import moment from 'moment';
import Constant from '../constant/Constant';
import crypto from 'crypto-js';
import systemField from '../constant/SystemField';
import { cloneDeep, drop } from 'lodash';
import UserLogConstant from '../constant/UserLogConstant';
import { I18n, Storage } from 'aws-amplify';
import localStorageUtil from '@/common/utils/localStorageUtil';
import { matchPath } from 'react-router';
import { getCorporationById } from '@/service/organizationService';

const isImageFile = fileName => {
    if (isEmptyString(fileName)) {
        return false;
    }
    return /\.(jpe?g|tiff?|png|jpg|webp|bmp|gif|jpe|jfif)$/i.test(fileName);
};

const isJson = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

const convertJSONDataToObject = data => {
    if (isEmptyObject(data) || isEmptyString(data)) {
        return {};
    }

    if (isEmptyArray(data)) {
        return [];
    }

    if (isJson(data)) {
        return JSON.parse(data);
    }
    return data;
};

const getUrlParameter = name => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getTextByLanguage = (language, strName) => {
    try {
        if (isEmptyObject(strName) || isEmptyString(strName) || isEmptyString(language)) {
            return '';
        }
        strName = typeof strName === 'object' ? JSON.stringify(strName) : strName;
        let objName = Utils.convertJSONDataToObject(strName);
        return getValueString(objName[`${language}`]?.text);
    } catch (err) {
        console.log('getTextByLanguage :>> ', err);
        console.log('getTextByLanguage :>> ', strName);
    }
};

const getCorporationName = (language, corporationDetail) => {
    let strName = corporationDetail?.name;

    try {
        if (isEmptyObject(strName) || isEmptyString(strName) || isEmptyString(language)) {
            return '';
        }
        strName = typeof strName === 'object' ? JSON.stringify(strName) : strName;
        let objName = Utils.convertJSONDataToObject(strName);
        let orgName = getValueString(objName[`${language}`]?.text);
        if (corporationDetail.active === Constant.CORPORATION_ACTIVE.BLOCK) {
            orgName = `${orgName} (${Constant.SUFFIX_TEXT.BLOCK_CORPORATION})`;
        }
        if (corporationDetail.active === Constant.CORPORATION_ACTIVE.DELETED) {
            orgName = `${orgName} (${Constant.SUFFIX_TEXT.DELETED_CORPORATION})`;
        }
        return orgName;
    } catch (err) {
        console.log('getTextByLanguage :>> ', err);
        // console.log('getTextByLanguage :>> ', strName);
    }
};

const getGuestContractUrlParameter = () => {
    let validUrl = isCreateGuestContractUrl();
    if (!validUrl) {
        return null;
    }
    let data = window.location.pathname.replace(Constant.CREATE_GUEST_CONTRACT_ROUTER, '');
    return data;
};

const isCreateGuestContractUrl = () => {
    if (!window.location.pathname.startsWith(Constant.CREATE_GUEST_CONTRACT_ROUTER)) {
        return false;
    }
    let paramCreateGuestContract = window.location.pathname.replace(
        Constant.CREATE_GUEST_CONTRACT_ROUTER,
        ''
    );
    if (paramCreateGuestContract.indexOf('/') >= 0) {
        return false;
    }
    return true;
};

const isApprovePageUrl = () => {
    if (!window.location.pathname.startsWith(Constant.APPROVE_PAGE_ROUTER)) {
        return false;
    }
    let paramApprove = window.location.pathname.replace(Constant.APPROVE_PAGE_ROUTER, '');
    if (paramApprove.indexOf('/') >= 0) {
        return false;
    }
    return true;
};

const isConfirmCreateOrgPageUrl = () => {
    if (!window.location.pathname.startsWith(Constant.CONFIRM_CREATE_ORGANIZATION_ROUTER)) {
        return false;
    }
    let paramApprove = window.location.pathname.replace(
        Constant.CONFIRM_CREATE_ORGANIZATION_ROUTER,
        ''
    );
    if (paramApprove.indexOf('/') >= 0) {
        return false;
    }
    return true;
};

const isConfirmCreateGuestOrgPageUrl = () => {
    if (!window.location.pathname.startsWith(Constant.CONFIRM_CREATE_GUEST_CONTRACT_ROUTER)) {
        return false;
    }
    let paramApprove = window.location.pathname.replace(
        Constant.CONFIRM_CREATE_GUEST_CONTRACT_ROUTER,
        ''
    );
    if (paramApprove.indexOf('/') >= 0) {
        return false;
    }
    return true;
};

const isLoginPageUrl = () => {
    let pathName = window.location.pathname;
    if (pathName.startsWith(Constant.LOGIN_ROUTER)) {
        return true;
    }
    return false;
};

const isEmptyString = str => {
    return typeof str === 'undefined' || str === null || str === '';
};

const isEmptyObject = obj => {
    return (
        typeof obj === 'undefined' ||
        obj === null ||
        (Object.keys(obj).length === 0 && obj.constructor === Object)
    );
};

const readFileAsArrayBuffer = file => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = () => {
            resolve(reader);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

const isEmptyArray = arr => {
    return typeof arr === 'undefined' || arr === null || arr.length === 0 || isEmptyObject(arr);
};

const getUserType = userType => {
    if (typeof userType === 'undefined' || userType === null) {
        return '';
    }
    let type;
    switch (userType) {
        case Constant.USER_TYPE.CUSTOMER:
            type = 'Customer';
            break;
        case Constant.USER_TYPE.GENERAL:
            type = I18n.get('GENERAL_USER');
            break;
        case Constant.USER_TYPE.INTERNAL:
            type = I18n.get('INTERNAL_USER');
            break;
        default:
            type = '';
    }
    return type;
};

const getTimeToDateNow = dateTimeInput => {
    const now = moment(new Date()); //todays date
    const createTime = moment(dateTimeInput);
    const duration = moment.duration(now.diff(createTime));

    const days = duration.asDays();
    const day = Math.trunc(days);

    const hours = (days - day) * 24;
    const hour = Math.trunc(hours);

    const minute = Math.trunc((hours - hour) * 60);

    return { day, hour, minute };
};

const sortByCreatedAtDesc = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    });
};

const sortByCreatedAtAsc = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
    });
};

const sortByUpdatedAtDesc = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
};

const sortByUpdatedAtAsc = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return new Date(a.updatedAt) - new Date(b.updatedAt);
    });
};

const sortByDeletedAtAsc = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return new Date(a.deletedAt) - new Date(b.deletedAt);
    });
};

const sortByDrillDownLabelAsc = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return a.label.localeCompare(b.label);
    });
};

const sortByHitNumberDesc = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return b.hitNumber - a.hitNumber;
    });
};

const sortDrillDownByNo = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return a.no - b.no;
    });
}

const isValidJSONString = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        console.log('e----------------',e)
        return false;
    }
    return true;
};

const getCorporationIdFromContentId = contentId => {
    let corporationId = '';
    if (!isEmptyString(contentId)) {
        const arrContentId = contentId.split('-');
        if (!isEmptyArray(arrContentId) && arrContentId.length > 2) {
            arrContentId.pop(); //remove counter
            arrContentId.pop(); //remove userNo
            if (!isEmptyArray(arrContentId)) {
                corporationId = arrContentId.join('-');
            }
        }
    }

    return corporationId;
};

const getDateTimeByFormatJapanYYYY_MM_DDHHMM = dateTimeString => {
    let dateTime = new Date(dateTimeString);
    ///
    let monthInt = dateTime.getMonth() + 1;
    let month = monthInt < 10 ? `0${monthInt}` : monthInt;
    let day = dateTime.getDate() < 10 ? `0${dateTime.getDate()}` : dateTime.getDate();
    let hours = dateTime.getHours() < 10 ? `0${dateTime.getHours()}` : dateTime.getHours();
    let minutes = dateTime.getMinutes() < 10 ? `0${dateTime.getMinutes()}` : dateTime.getMinutes();

    return `${dateTime.getFullYear()}/${month}/${day} ${hours}:${minutes}`;
};

const getDateTimeByFormatYYYYMMDDHHMM = dateTimeString => {
    if (!dateTimeString) return '';
    let dateTime = new Date(dateTimeString);
    let monthInt = dateTime.getMonth() + 1;
    let month = monthInt < 10 ? `0${monthInt}` : monthInt;
    let day = dateTime.getDate() < 10 ? `0${dateTime.getDate()}` : dateTime.getDate();
    let hours = dateTime.getHours() < 10 ? `0${dateTime.getHours()}` : dateTime.getHours();
    let minutes = dateTime.getMinutes() < 10 ? `0${dateTime.getMinutes()}` : dateTime.getMinutes();

    return `${dateTime.getFullYear()}/${month}/${day} ${hours}:${minutes}`;
};

const getDateByFormatYYYY_MM_DD = dateTimeString => {
    let dateTime = new Date(dateTimeString);
    let month = `${dateTime.getMonth() + 1}`;
    let day = `${dateTime.getDate()}`;
    if (month.length < 2) {
        month = `0${month}`;
    }
    if (day.length < 2) {
        day = `0${day}`;
    }
    return `${dateTime.getFullYear()}/${month}/${day}`;
};

const reducerDataList = (state, action) => {
    switch (action.type) {
        case Constant.ACTION_CHANGE_LIST_DATA_TYPE.INITIAL:
            return action.posts;
        case Constant.ACTION_CHANGE_LIST_DATA_TYPE.ADD:
            return [...state, ...action.posts];
        case Constant.ACTION_CHANGE_LIST_DATA_TYPE.PUSH:
            return [action.post, ...state];
        case Constant.ACTION_CHANGE_LIST_DATA_TYPE.REPLACE:
            state.splice(action.postIndex, 1, action.newPost);
            return state;
        case Constant.ACTION_CHANGE_LIST_DATA_TYPE.CONCAT:
            state = state.concat(action.posts);
            return state;
        default:
            return state;
    }
};

const validateEmail = email => {
    if (isEmptyString(email)) {
        return false;
    }
    const re = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    return re.test(email);
};

const validateEmailVer2 = email => {
    if (!email) {
        return false; // Email address cannot be empty
    }

    // Do not allow special characters like ~!#$%^&(),<> or icons in the local part
    const specialCharPattern = /[~!#$%^&(),<>]/;
    if (specialCharPattern.test(email)) {
        return false; // Email contains invalid special characters
    }

    // Split local part and domain by the "@" symbol
    const emailParts = email.split("@");
    if (emailParts.length !== 2) {
        return false; // Email must contain exactly one "@" symbol
    }

    const [localPart, domainPart] = emailParts;

    // Ensure no consecutive special characters and special characters are not at the start or end
    const localPartPattern = /^[a-zA-Z0-9]+([._+-]?[a-zA-Z0-9]+)*$/;
    if (!localPartPattern.test(localPart)) {
        return false; // Local part has consecutive special characters or starts/ends with them
    }

    // Do not allow ".." in the email
    if (email.includes("..", "--", "__", "++")) {
        return false; // Email cannot contain consecutive dots
    }

    // Validate the domain part to allow multiple subdomains (e.g., gaugau.dbdesk-dev.ominext.dev)
    const domainPattern = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
    if (!domainPattern.test(domainPart)) {
        return false; // Domain is not valid
    }

    // Do not allow "-" before or after "@"
    if (/[-]$/.test(localPart) || /^-/.test(domainPart)) {
        return false; // "-" cannot appear before or after "@"
    }

    // Check "." at the beginning or end of the domain part
    if (/^\./.test(domainPart) || /\.$/.test(domainPart)) {
        return false; // Domain cannot start or end with "."
    }

    return true; // Email is valid
};


const getMenuSelectedByUrl = (defaultMenu, userId) => {
    let currentLocation = window.location;
    let menuType = '';
    switch (currentLocation.pathname) {
        case Constant.USER_MANAGEMENT_ROUTER:
            menuType = Constant.MENU_SELECT_TYPE.SYSTEM;
            break;
        case Constant.UPDATE_ORG_PROFILE_ROUTER:
            menuType = Constant.MENU_SELECT_TYPE.SYSTEM;
            break;
        case Constant.PROJECT_MANAGEMENT_ROUTER:
            menuType = Constant.MENU_SELECT_TYPE.SYSTEM;
            break;
        case `${Constant.USER_PROFILE_ROUTER}/${userId}`:
            menuType = Constant.MENU_SELECT_TYPE.USER_PROFILE;
            break;
        case `${Constant.PROJECT_USER_MANAGEMENT_ROUTER}`:
            menuType = Constant.MENU_SELECT_TYPE.PROJECT_SETTING;
            break;
        default:
            menuType = defaultMenu;
    }
    return menuType;
};

const isProjectManagerOfProject = (project, projectManagerList) => {
    if (!project || !projectManagerList || !projectManagerList.length) {
        return false;
    }
    if (projectManagerList.indexOf(project) > -1) {
        return true;
    }
    return false;
};

const getCloudFrontUrlForCorporationLogo = params => {
    let filename = params?.corporationDetail?.logoFileName;

    if (isEmptyString(params.image) || !filename) {
        return Constant.LOGO_DEFAULT;
    }
    let s3Key = params.image + '/' + filename;
    // if (params?.corporationDetail && params?.corporationDetail?.updatedAt) {
    //     const updatedAt = new Date(params?.corporationDetail?.updatedAt);
    //     const lastReleaseDate = new Date("2023-03-27");
    //     s3Key = params.image;
    // }

    const expiryTime = new Date().getTime() + Constant.S3_URL_EXPIRY_TIME * 60000;
    let paramsUrlObj = {
        expiryTime: expiryTime,
        keyFile: `/public/${s3Key}`,
        publicAll: true
    };
    if (params.type === Constant.S3_FILE_TYPE.CORPORATION_LOGO_PROFILE_FILE) {
        paramsUrlObj.type = Constant.S3_FILE_TYPE.CORPORATION_LOGO_LOGIN_FILE;
        paramsUrlObj.currentCorporationId = params.corporationDetail.id;
        paramsUrlObj.corporationId = params.organizationDomain;
    }
    const paramsUrl = encodeURIComponent(
        crypto.AES.encrypt(JSON.stringify(paramsUrlObj), Constant.S3_URL_AES_ENCODE_KEY).toString()
    );
    return `${process.env.REACT_APP_CLOUD_FRONT_URL}/${paramsUrl}`;
};

const getCloudFrontUrlOfFilePublicAll = s3Key => {
    const expiryTime = 604800;
    let paramsUrlObj = {
        expiryTime: expiryTime,
        keyFile: s3Key,
        publicAll: true
    };
    const paramsUrl = encodeURIComponent(
        crypto.AES.encrypt(JSON.stringify(paramsUrlObj), Constant.S3_URL_AES_ENCODE_KEY).toString()
    );
    return `${process.env.REACT_APP_CLOUD_FRONT_URL}/${paramsUrl}`;
};

const isEmpty = data => {
    return data === undefined || data === null;
};

const getValueString = str => {
    if (str) {
        return str?.toString();
    }
    return '';
};

const getPrefixOfId = id => {
    if (isEmptyString(id)) {
        return '';
    }
    const strArray = id.split('_');
    return strArray[0];
};

/**
 * Check admin user
 * @param {*} user
 * @returns isAdmin
 */
export const isAdminUser = user => {
    return (
        !Utils.isEmptyObject(user) &&
        (Constant.PRIVILEGE_USER.ADMIN_OWNER === user.privilege ||
            Constant.PRIVILEGE_USER.ADMIN === user.privilege)
    );
};

/**
 * Get type of user
 * @param {*} user
 * @returns user Type
 */
export const getTypeUser = userDetail => {
    if (isEmptyObject(userDetail)) {
        return '';
    }
    if (!userDetail.type) {
        return '';
    }
    return userDetail.type;
};

/**
 * Get full name of user
 * @param {*} language
 * @param {*} userDetail
 * @returns fullName of user
 */
export const getFullNameOfUser = (language, userDetail, noSpace) => {
    if (isEmptyString(language) || isEmptyObject(userDetail)) {
        return '';
    }
    let spaceBetween = ' ';
    if (noSpace) {
        spaceBetween = '';
    }
    let fullName = `${getTextByLanguage(
        language,
        userDetail.lastName
    )}${spaceBetween}${getTextByLanguage(language, userDetail.firstName)}`;

    // if (userDetail.active === Constant.ACTIVE_FLAG.DELETED) {
    //     fullName = `${fullName} (${Constant.SUFFIX_TEXT.DELETED_USER})`;
    // }
    return fullName?.toString();
};

/**
 * Get full name of user
 * @param {*} language
 * @param {*} userDetail
 * @returns fullName of user
 */
export const getFullNameOfUserWithCorporationStatus = (
    language,
    userDetail,
    noSpace,
    ticketInfo
) => {
    let listBlockedOrg = ticketInfo.listBlockedOrg;
    let listDeleteOrg = ticketInfo.listDeletedOrg;
    if (userDetail?.id?.includes('anonymous_')) {
        userDetail.firstName = '{"jp":{"A":"F","text":"未登録カスタマー"}}';
    }
    if (isEmptyString(language) || isEmptyObject(userDetail)) {
        return '';
    }
    let spaceBetween = ' ';
    if (noSpace) {
        spaceBetween = '';
    }
    let fullName = `${getTextByLanguage(
        language,
        userDetail.lastName
    )}${spaceBetween}${getTextByLanguage(language, userDetail.firstName)}`;
    let nameOfUser = fullName?.toString();
    if (listBlockedOrg?.includes(userDetail.affiliationOrganization)) {
        nameOfUser = `${nameOfUser} (${Constant.SUFFIX_TEXT.BLOCK_CORPORATION})`;
    }
    if (listDeleteOrg?.includes(userDetail.affiliationOrganization)) {
        nameOfUser = `${nameOfUser} (${Constant.SUFFIX_TEXT.DELETED_CORPORATION})`;
    }
    return nameOfUser;
};

/**
 * Find index in array by id
 * @param {*} id
 * @param {*} arr
 * @returns index
 */
export const findIndexInArrayById = (id, arr) => {
    if (isEmptyArray(arr)) return -1;
    let index = arr.findIndex((item, i) => {
        return item.id === id;
    });
    return index === undefined || index === null ? -1 : index;
};

export const findObjectById = (id, arr) => {
    if (Utils.isEmptyArray(arr)) {
        return null;
    } else {
        let obj = arr.find(item => {
            return item.id === id;
        });
        return Utils.isEmptyObject(obj) ? null : cloneDeep(obj);
    }
};

/**
 * Remove item in array by id
 * @param {*} id
 * @param {*} arr
 * @returns array
 */
export const removeItemInArrayById = (id, arr) => {
    let arrNew = arr.filter(item => item.id !== id);
    return arrNew;
};

export const getCustomFieldValueInFormData = (formData, fieldListData) => {
    let customFieldObj = {};
    let tagField = {};
    for (const key in formData) {
        if (getPrefixOfId(key) === 'field') {
            const fieldData = fieldListData.filter(field => field.fieldId === key);
            if (!fieldData || Utils.isEmptyArray(fieldData)) continue;
            // console.log('createTicket :>> fieldData ', fieldData);
            // console.log('createTicket :>> fieldData ', formData);
            // console.log('fields :>> fieldData[0].structure.UI ', fieldData[0].structure.UI);

            switch (fieldData[0].structure.UI) {
                case Constant.FIELD_UI.TEXT_WITH_CHIP:
                case Constant.FIELD_UI.TAG:
                    tagField[key] = formData[key];
                    break;
                case Constant.FIELD_UI.IFRAME:
                    customFieldObj[key] = formData[key]?.trim();
                    break;
                case Constant.FIELD_UI.URL:
                    let arrURL = [];
                    let stringArrURL = formData[key]?.trim();
                    let arrURLInput = stringArrURL.split(/\r?\n/);
                    if (!Utils.isEmptyArray(arrURLInput)) {
                        arrURLInput.forEach(url => {
                            url = url.trim();
                            if (url) {
                                arrURL.push(url);
                            }
                        });
                    }
                    customFieldObj[key] = arrURL;
                    break;
                default:
                    if (typeof formData[key] === 'string' && !Utils.isEmptyString(formData[key])) {
                        customFieldObj[key] = formData[key]?.trim();
                    } else {
                        customFieldObj[key] = formData[key];
                    }

                    break;
            }
        }
    }
    customFieldObj.tagField = tagField;
    return JSON.stringify(customFieldObj);
};

export const getEditTicketData = (formData, fieldListData) => {
    const getTagValue = tagValueList => {
        let listSelectedItem = [];
        if (!isEmptyArray(tagValueList)) {
            for (const tagItem of tagValueList) {
                if (!Utils.isEmptyObject(tagItem)) {
                    listSelectedItem.push(tagItem.value);
                }
            }
        }
        return listSelectedItem;
    };
    let customFieldObj = {};
    let tagField = {};
    for (const key in formData) {
        if (getPrefixOfId(key) === 'field') {
            const fieldData = fieldListData.filter(field => field.fieldId === key);

            switch (fieldData[0]?.structure?.UI) {
                case Constant.FIELD_UI.TAG:
                case Constant.FIELD_UI.TEXT_WITH_CHIP:
                    customFieldObj[key] = getTagValue(formData[key]);
                    tagField[key] = formData[key];
                    break;
                case Constant.FIELD_UI.IFRAME:
                    customFieldObj[key] = formData[key]?.trim();
                    break;
                case Constant.FIELD_UI.URL:
                    let arrURL = [];
                    let stringArrURL = formData[key]?.trim();
                    let arrURLInput = stringArrURL.split(/\r?\n/);
                    if (!Utils.isEmptyArray(arrURLInput)) {
                        arrURLInput.forEach(url => {
                            url = url.trim();
                            if (url) {
                                arrURL.push(url);
                            }
                        });
                    }
                    customFieldObj[key] = arrURL;
                    break;
                default:
                    customFieldObj[key] = formData[key];
                    break;
            }
        }
    }
    customFieldObj.tagField = tagField;
    return customFieldObj;
};

export const validateURL = url => {
    let urlTrim = url.trim();
    // let formatRegex = Constant.URL_REGEX;
    const urlType = detectUrlType(urlTrim);
    // console.log('urlType :>> ', urlType);
    let validateResult = false;
    switch (urlType) {
        case Constant.PROTOCOL.HTTP:
        case Constant.PROTOCOL.HTTPS:
        case Constant.PROTOCOL.FTP:
        case Constant.PROTOCOL.FILE:
        case Constant.PROTOCOL.NOTES:
            validateResult = true;
            break;
        default:
            break;
    }
    if (checkWhitespace(urlTrim)) {
        return false;
    }
    // console.log('urlType :>> ', urlType,formatRegex,urlTrim);
    return validateResult;
};

const detectUrlType = (url) => {
    const formats = [
        { type: Constant.PROTOCOL.HTTP, regex: /^http:\/\// },
        { type: Constant.PROTOCOL.HTTPS, regex: /^https:\/\// },
        { type: Constant.PROTOCOL.FTP, regex: /^ftp:\/\// },
        { type: Constant.PROTOCOL.FILE, regex: /^file:\/\// },
        { type: Constant.PROTOCOL.NOTES, regex: /^(Notes|notes):\/\// },
    ];
    for (const format of formats) {
        if (format.regex.test(url)) {
            return format.type;
        }
    }
    return '';
}

export const checkWhitespace = (string) => {
    return /\s(?![^/]*$)/.test(string);
}

const isSystemField = keyId => {
    const listSystemField = systemField.filter(sysField => sysField.id === keyId);
    return listSystemField.length > 0;
};
export const isTypeObject = data => {
    if (isEmpty(data)) {
        return false;
    }
    return typeof data === 'object';
};

export const getNumberOfLines = str => {
    if (isEmptyString(str)) {
        return 4;
    } else {
        const elementsArray = str.split('\n');
        if (isEmptyArray(elementsArray)) {
            return 4;
        }
        return elementsArray.length <= 1 ? elementsArray.length : elementsArray.length - 1;
    }
};

export const getPanelInfo = (panelId, panelList) => {
    if (!panelList || Utils.isEmptyArray(panelList)) return;
    return panelList.find(panel => panel.id === panelId);
};

export const encodeDataByCrypto = (data, key) => {
    data = typeof data === 'string' ? data : JSON.stringify(data);
    const encryptData = crypto.AES.encrypt(data, key).toString();
    let encodeURI = encodeURIComponent(encryptData);
    return encodeURI;
};

export const decodeDataByCrypto = (data, key) => {
    const encryptData = crypto.AES.decrypt(decodeURIComponent(data), key);
    let result = convertJSONDataToObject(encryptData.toString(crypto.enc.Utf8));
    return result;
};

export const isAnonymousQATicket = ticket => {
    if (!ticket || !ticket.hasOwnProperty('anonymousQaStatus') || !ticket.anonymousQaStatus) {
        return false;
    }

    return true;
};

export const isAnonymousQATicketFromLiveChat = ticket => {
    if (ticket?.anonymousUserInfo?.type === Constant.ANONYMOUS_TYPE.CHATBOT_LIVECHAT) {
        return true;
    }
    return false;
};

export const isAnonymousLiveChatWaitingTicket = ticket => {
    if (ticket?.anonymousUserInfo?.type === Constant.ANONYMOUS_TYPE.CHATBOT_LIVECHAT) {
        if (ticket?.anonymousQaStatus === Constant.ANONYMOUS_TICKET_STATUS.CHAT_NOW) return false;
        return true;
    }
    return false;
};

export const isAnonymousQAWaitingTicket = ticket => {
    return (
        isAnonymousQATicket(ticket) &&
        ticket?.anonymousQaStatus === Constant.ANONYMOUS_TICKET_STATUS.WAITING_APPROVE
    );
};

export const isAnonymousQAWaitingOrEndLiveChatTicket = ticket => {
    return (
        isAnonymousQATicketFromLiveChat(ticket) &&
        (ticket?.anonymousQaStatus === Constant.ANONYMOUS_TICKET_STATUS.WAITING_APPROVE ||
            ticket?.anonymousQaStatus ===
                Constant.ANONYMOUS_TICKET_STATUS.END_LIVE_CHAT_AGENT_NOT_RESPONSE ||
            ticket?.anonymousQaStatus ===
                Constant.ANONYMOUS_TICKET_STATUS.END_LIVE_CHAT_CASE_TICKET_WAITING) && !ticket?.deletedAt
    );
};

export const isAnonymousQALiveChatDeleted = ticket => {
    return isAnonymousQATicketFromLiveChat(ticket) && ticket?.deletedAt;
};

export const isAnonymousQARejectTicket = ticket => {
    return (
        isAnonymousQATicket(ticket) &&
        ticket?.anonymousQaStatus === Constant.ANONYMOUS_TICKET_STATUS.REJECTED
    );
};

export const isAnonymousQAApproveTicket = ticket => {
    return (
        isAnonymousQATicket(ticket) &&
        ticket?.anonymousQaStatus === Constant.ANONYMOUS_TICKET_STATUS.APPROVE
    );
};

const isAnonymousQALiveChatTicket = ticket => {
    return ticket?.anonymousQaStatus === Constant.ANONYMOUS_TICKET_STATUS.CHAT_NOW;
};

const isAnonymousQAEndLiveChatTicket = ticket => {
    return ticket?.anonymousQaStatus === Constant.ANONYMOUS_TICKET_STATUS.END_LIVE_CHAT;
};

export const isDisableApproveRejectAnonymousQaTicket = (ticket) => {
    if (!isAnonymousQAWaitingTicket(ticket)) return true;
    return false;
};

export const isExpiredTimeLogin = expiredAtByMiliseconds => {
    const todayByMiliseconds = new Date(Date.now()).getTime();

    // console.log({expiredAtByMiliseconds: expiredAtByMiliseconds, todayByMiliseconds: todayByMiliseconds});

    return todayByMiliseconds > expiredAtByMiliseconds ? true : false;
};

const isExpired = expireAt => {
    if (Utils.isEmpty(expireAt)) {
        return false;
    }
    const todayByMiliseconds = new Date(Date.now()).getTime();

    return todayByMiliseconds > expireAt ? true : false;
};

export const getUserStatus = user => {
    const expiredAtByMiliseconds = isNaN(user.expireAt)
        ? new Date(user.expireAt).getTime()
        : user.expireAt;
    try {
        if (user.active === Constant.ACTIVE_FLAG.REJECT) {
            return Constant.STATUS_OF_USER.REJECT;
        }

        if (user.active === Constant.ACTIVE_FLAG.DELETED) {
            return Constant.STATUS_OF_USER.DELETED;
        }

        if (user.active === Constant.ACTIVE_FLAG.NOT_INVITED) {
            return Constant.STATUS_OF_USER.NOT_INVITED;
        }

        if (user.active) {
            return Constant.STATUS_OF_USER.ACTIVE;
        } else if (isEmpty(user.expireAt)) {
            return Constant.STATUS_OF_USER.WAITING;
        } else if (!expiredAtByMiliseconds || isNaN(expiredAtByMiliseconds)) {
            return Constant.STATUS_OF_USER.WAITING;
        }
        return isExpiredTimeLogin(expiredAtByMiliseconds)
            ? Constant.STATUS_OF_USER.REJECT
            : Constant.STATUS_OF_USER.WAITING;
    } catch (err) {
        console.error({ expiredAt: err });
        return Constant.STATUS_OF_USER.WAITING;
    }
};

/**
 * getB2CStatusInProject
 * @param {*} user
 * @param {*} activeInProject
 * @returns status
 */
export const getB2CStatusInProject = (user, activeInProject) => {
    if (!user || isEmptyObject(user) || !activeInProject) return;
    let statusInProject;
    let statusInCorporation = getUserStatus(user);

    switch (true) {
        case statusInCorporation === Constant.STATUS_OF_USER.WAITING &&
            activeInProject === Constant.ACTIVE_FLAG.ACTIVE:
            statusInProject = Constant.STATUS_OF_USER.WAITING;
            break;
        case activeInProject === Constant.ACTIVE_FLAG.DELETED:
            statusInProject = Constant.STATUS_OF_USER.DELETED;
            break;
        case statusInCorporation === Constant.STATUS_OF_USER.REJECT:
            statusInProject = Constant.STATUS_OF_USER.REJECT;
            break;
        case statusInCorporation === Constant.STATUS_OF_USER.ACTIVE &&
            activeInProject === Constant.ACTIVE_FLAG.ACTIVE:
            statusInProject = Constant.STATUS_OF_USER.ACTIVE;
            break;
        case statusInCorporation === Constant.STATUS_OF_USER.DELETED:
            statusInProject = Constant.STATUS_OF_USER.DELETED;
            break;
        default:
            break;
    }

    return statusInProject;
};

export const getShortName = name => {
    if (isEmptyString(name)) {
        return '';
    }

    let firstName = name.substring(0, 1);
    let lastName = '';
    if (/[^a-z0-9\-\p{L}\p{N}\p{S}]/gu.test(firstName)) {
        firstName = name.substring(0, 2);
        lastName = name.substring(2, 3);
        if (/[^a-z0-9\-\p{L}\p{N}\p{S}]/gu.test(lastName)) {
            lastName = name.substring(2, 4);
        }
    } else {
        lastName = name.substring(1, 2);
        if (/[^a-z0-9\-\p{L}\p{N}\p{S}]/gu.test(lastName)) {
            lastName = name.substring(1, 3);
        }
    }
    let shortName = `${firstName}${lastName}`;
    return shortName;
};

export const formatStringUpperCaseFirstLetter = name => {
    if (isEmpty(name) || isEmptyString(name)) {
        return '';
    }
    return name[0].toUpperCase() + name.slice(1).toLowerCase();
};

export const getUpdatedTimeTicket = (isHelpDesk, ticket) => {
    const updatedObject = isHelpDesk
        ? Utils.convertJSONDataToObject(ticket.updatedForDesk)
        : Utils.convertJSONDataToObject(ticket.updatedForCustomer);
    const defaultResult = {
        updatorUserName: ticket.updatorUserName,
        updatedTime: getDateTimeByFormatYYYYMMDDHHMM(ticket.updatedAt)
    };

    if (isEmptyString(updatedObject) || isEmptyObject(updatedObject) || ticket.ticketType === Constant.TICKET_TYPE.TASK ) {
        return defaultResult;
    }

    const ticketEvent = updatedObject.ticketEvent;
    const chatEvent = updatedObject.chatEvent;
    let ticketUpdatedTime = ticketEvent?.updatedTime ? new Date(ticketEvent.updatedTime) : null;
    let chatUpdatedTime = chatEvent?.updatedTime ? new Date(chatEvent.updatedTime) : null;

    if (!ticketUpdatedTime || !chatUpdatedTime) {
        if (chatUpdatedTime) {
            chatEvent.updatedTime = getDateTimeByFormatYYYYMMDDHHMM(chatUpdatedTime);
            return chatEvent;
        }
        if (ticketUpdatedTime) {
            ticketEvent.updatedTime = getDateTimeByFormatYYYYMMDDHHMM(ticketUpdatedTime);
            return ticketEvent;
        }
        return defaultResult;
    }

    if (ticketUpdatedTime > chatUpdatedTime) {
        ticketEvent.updatedTime = getDateTimeByFormatYYYYMMDDHHMM(ticketUpdatedTime);
        return ticketEvent;
    }

    chatEvent.updatedTime = getDateTimeByFormatYYYYMMDDHHMM(chatUpdatedTime);
    if (ticket.ticketType === Constant.TICKET_TYPE.TASK) {
        chatEvent.updatedTime = getDateTimeByFormatYYYYMMDDHHMM(new Date(ticket.updatedAt));  
    }
    return chatEvent;
};
function onClickSharedTicketLink(e) {
}


/**
 * Detect url and convert to hyper link with <a></a> tag4
 * @param {*} text
 * @returns
 */
export const detectUrl = (content, className,isFromQA,isPublicSite) => {
    getShareLinkUrls(content)
    const text = `${content}`;
    // const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urlRegex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
    return text.replace(urlRegex, function(url) {
        let urlDomain = new URL(url).origin;
        let urlPathName = new URL(url).pathname;
        let matchUrl = matchPath(urlPathName, {
            path: Constant.SHARE_TICKET_ROUTER,
            exact: true,
            strict: true
        });
        // Compare doamin and router
        if (urlDomain === window.location.origin && !Utils.isEmptyObject(matchUrl) && matchUrl.isExact && !isPublicSite) {
            return isFromQA ? `<span class='shared-ticket-link' style="text-decoration: underline" onClick="window.onClickUrlViewTicketFromQAChatThread('${url}')">${url}</span>` : `<span class='shared-ticket-link' style="text-decoration: underline" onClick="window.onClickUrlViewTicket('${url}')">${url}</span>`;
            // return isFromQA ? `<span class='shared-ticket-link' onClick="window.onClickUrlViewTicketFromQAChatThread('${url}')">${url}</span>` : `<span class='shared-ticket-link' onClick="window.onClickUrlViewTicket('${url}')">${url}</span>`;
        }
        return `<a href="${url.replace('&nbsp','')}" target="_blank" style="text-decoration: underline" class="${className}">${url.replace('&nbsp','')}</a>`;
        // return '<a target="_blank" class="' + className + '" href="' + url + '">' + url + '</a>';
    });
};

/**
 * Replaces URLs in a text string with anchor tags (<a>) with the target="_blank" attribute.
 * 
 * This function takes a text string as input and returns a new string with all URLs wrapped in anchor tags. 
 * When clicked, these links will open in a new tab or window.
 *
 * @param {string} text The text string to process.
 * @returns {string} The modified text string with replaced URLs.
 */

function truncateText(text, maxLength = 36) {
    // Kiểm tra nếu độ dài của chuỗi lớn hơn maxLength
    if (text.length > maxLength) {
        // Cắt chuỗi và thêm "..." vào cuối
        return text.slice(0, maxLength);
    }
    // Trả về chuỗi ban đầu nếu không cần cắt ngắn
    return text;
}

const removeExtraCharactersOfIdTicket = (id) => {
    let parts = id.split('_');
    let prefix = parts[0];
    let suffix = parts.slice(1).join('_');

    const newSuffix = truncateText(suffix,36);

    return `${prefix}_${newSuffix}`

}

export const removeTwoSpacesBetweenSpecialChars = (str) => {
    // Regular expression to find two spaces between special characters
    const regex = /([,{}\[\]"`'‘’])\s*([,{}\[\]"`'‘’])/g;
    // Use replace to remove the two spaces and keep the special characters
    return str.replace(regex, (match, p1, p2) => `${p1}${p2}`);
}

/**
 * using for hotfix to replace when using markdown for display data AIChat
 * @param {*} str 
 * @returns 
 */
function hasMatchingPairs(str) {
    // symmetrical chars 
    const pairs = {
        ')': '(',
        ']': '[',
        '}': '{',
        '"': '"',
        "'": "'",
        '’': "‘",
        '`': '`'
    };

    // get last chart
    const lastChar = str.slice(-1);

    // checking last chart in pairs or not
    if (!Object.keys(pairs).includes(lastChar)) {
        return false;
    }

     //Check if the preceding character is the same character as the last character
     if (str.slice(-2, -1) === lastChar) {
        return false;
    }

    // Get the opposite sign corresponding to the last character
    const matchingChar = pairs[lastChar];

    // Check for the existence of a backspace in the string before the last character
    return str.slice(0, -1).includes(matchingChar);
}

export const replaceUrls = (text = '', isFromQA = false, isPublicSite = false, className = '', isSendFromMe = false, isAIChat = false) => {
    if (!text) return '';
    // console.log('text-------------------',text)

    const urlRegex = /((http|https|ftp|file|Notes|notes):\/\/([^\s\/]+)([^\s]*)|((file):\/\/([^\s]*)))/g; // Regex để nhận diện các URL có thể liền kề với văn bản
    
    const regexTitleIsUrl = /\[(.*?)\]/g; // title is url into []
    let matches;
    let urlsNeedReplace = [];
    
    
    while ((matches = regexTitleIsUrl.exec(text)) !== null) {
        if (matches[1].match(urlRegex))
            urlsNeedReplace.push({
                text: matches[1], // get content into []
                start: matches.index +1, // started position of replace string
                end: regexTitleIsUrl.lastIndex -1 // ended position of replace string
            });
    }

    if (!isEmptyArray(urlsNeedReplace)) {
        for (const [idx, urlNeedReplace] of urlsNeedReplace.entries()) {
            let started = urlNeedReplace?.start;
            let ended = urlNeedReplace?.end;
            if (idx > 0) {
                started = urlNeedReplace?.start + 2 * idx;
                ended = urlNeedReplace?.end + 2 * idx;
            }
            text = text?.slice(0, started) + ` ${urlNeedReplace?.text} ` + text?.slice(ended);
        }
    }

    // Sử dụng hàm replace với regex để thay thế các URL
    const replacedText = text.replace(urlRegex, (url) => {
        const urlType = detectUrlType(url);
        let whiteSpace = ""
        let whiteSpaceAndParenthesis = ""
        let urlDisplay = url
        let urlRedirect = url

        if (!isSendFromMe && isAIChat) {
            whiteSpace = `&nbsp;`;
            let replaceChar = ""
            let urlForDisplay = url;
            let urlForRedirect = url;
            urlForDisplay = urlForDisplay.replaceAll('&nbsp;,',' ')
            urlForDisplay = urlForDisplay.replaceAll(')&quot;',' )"')
            urlForDisplay = urlForDisplay.replaceAll('&quot;,',' "')
            urlForDisplay = urlForDisplay.replaceAll('&#39;,',' \'')
            urlForDisplay = urlForDisplay.replaceAll('&nbsp;',' ')
            urlForDisplay = urlForDisplay.replaceAll('&quot;',' "')
            urlForDisplay = urlForDisplay.replaceAll('&#39;',' \'')
            if (!hasMatchingPairs(urlForDisplay))
                urlDisplay = urlForDisplay.replace(/([)\]"\}\\’'`])$/g, match => {
                    replaceChar = match;
                    return '';
                });

            urlForRedirect = urlForRedirect.replaceAll('&nbsp;,','')
            urlForRedirect = urlForRedirect.replaceAll('&quot;,','"')
            urlForRedirect = urlForRedirect.replaceAll('&#39;,','\'')
            urlForRedirect = urlForRedirect.replaceAll('&nbsp;','')
            urlForRedirect = urlForRedirect.replaceAll('&quot;','"')
            urlForRedirect = urlForRedirect.replaceAll('&#39;','\'')
            if (!hasMatchingPairs(urlForRedirect))
                urlRedirect = urlForRedirect.replace(/([)\]"\}\\’'`])$/g, '').replace(/[\]"\}\\’'`]$/g, '');
            whiteSpaceAndParenthesis = `&nbsp;${replaceChar}`;
        }

        switch (urlType) {
            case Constant.PROTOCOL.HTTP:
            case Constant.PROTOCOL.HTTPS:
                const fullUrl = new URL(url);
                let urlDomain = fullUrl.origin;
                let urlPathName = fullUrl.pathname;
                let matchUrl = matchPath(urlPathName, {
                    path: Constant.SHARE_TICKET_ROUTER,
                    exact: true,
                    strict: true
                });

                // So sánh domain và router
                if (!Utils.isEmptyObject(matchUrl) && matchUrl.isExact && !isPublicSite) {
                    const params = matchUrl.params;
                    const id = params.id;
                    const newId = removeExtraCharactersOfIdTicket(id);
                    const targetUrl = url.replace(id,newId)
                    return isFromQA ?
                        `${whiteSpace}<span class='shared-ticket-link' style="text-decoration: underline" onClick="window.onClickUrlViewTicketFromQAChatThread('${targetUrl}')">${urlDisplay}</span>${whiteSpaceAndParenthesis}`:
                        `${whiteSpace}<span class='shared-ticket-link' style="text-decoration: underline" onClick="window.onClickUrlViewTicket('${targetUrl}')">${urlDisplay}</span>${whiteSpaceAndParenthesis}`;
                } else {
                    return `${whiteSpace}<a href="${urlRedirect}" target="_blank" style="text-decoration: underline" class="${className}">${urlDisplay}</a>${whiteSpaceAndParenthesis}`;
                }
            case Constant.PROTOCOL.FTP:
            case Constant.PROTOCOL.FILE:
            case Constant.PROTOCOL.NOTES:
                return `${whiteSpace}<a href="${urlRedirect}" target="_blank" style="text-decoration: underline" class="${className}">${urlDisplay}</a>${whiteSpaceAndParenthesis}`;
            default:
                return `${whiteSpace}${urlDisplay}${whiteSpaceAndParenthesis}`; // Trường hợp mặc định, trả về chính URL ban đầu
        }
    });

    return replacedText;
};


// export const getUpdatetorTicket = (ticket, isCustomer) => {
//     let updatedForCustomer = convertJSONDataToObject(ticket.updatedForCustomer);
//     let updatedForDesk = convertJSONDataToObject(ticket.updatedForDesk);
// }

const isNotChangedName = (language, nameForeign, nameTyping) => {
    if (
        isEmpty(nameForeign) ||
        isEmpty(nameTyping) ||
        isEmptyString(nameForeign) ||
        isEmptyString(nameTyping)
    ) {
        return true;
    }
    let newNameForeign = isEmpty(getTextByLanguage(language, nameForeign))
        ? nameForeign
        : getTextByLanguage(language, nameForeign);
    let newNameTyping = isEmpty(getTextByLanguage(language, nameTyping))
        ? nameTyping
        : getTextByLanguage(language, nameTyping);
    return newNameForeign === newNameTyping;
};

const setUpdatedTicketObject = (updateObject, currentUser) => {
    const currentTime = new Date().toISOString();
    let convertObject = Utils.convertJSONDataToObject(updateObject);
    convertObject.ticketEvent = {
        updatedTime: currentTime,
        updatedId: currentUser
    };

    if (!Utils.isEmptyObject(convertObject.ticketEvent)) {
        delete convertObject.ticketEvent.updatorUserName;
    }
    if (!Utils.isEmptyObject(convertObject.chatEvent)) {
        delete convertObject.chatEvent.updatorUserName;
    }

    return JSON.stringify(convertObject);
};

let charsets = {
    latin: { halfRE: /[!-~]/g, fullRE: /[！-～]/g, delta: 0xfee0 },
    hangul1: { halfRE: /[ﾡ-ﾾ]/g, fullRE: /[ᆨ-ᇂ]/g, delta: -0xedf9 },
    hangul2: { halfRE: /[ￂ-ￜ]/g, fullRE: /[ᅡ-ᅵ]/g, delta: -0xee61 },
    kana: {
        delta: 0,
        half: '｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝﾞﾟ',
        full:
            '。「」、・ヲァィゥェォャュョッーアイウエオカキクケコサシ' +
            'スセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワン゛゜'
    },
    extras: { delta: 0, half: '¢£¬¯¦¥₩\u0020|←↑→↓■°', full: '￠￡￢￣￤￥￦\u3000￨￩￪￫￬￭￮' }
};

let toFull = set => c =>
    set.delta
        ? String.fromCharCode(c.charCodeAt(0) + set.delta)
        : [...set.full][[...set.half].indexOf(c)];
let toHalf = set => c =>
    set.delta
        ? String.fromCharCode(c.charCodeAt(0) - set.delta)
        : [...set.half][[...set.full].indexOf(c)];
let re = (set, way) => set[way + 'RE'] || new RegExp('[' + set[way] + ']', 'g');

let sets = Object.keys(charsets).map(i => charsets[i]);
const toFullWidth = str0 =>
    sets.reduce((str, set) => str.replace(re(set, 'half'), toFull(set)), str0);

const toHalfWidth = str0 =>
    sets.reduce((str, set) => str.replace(re(set, 'full'), toHalf(set)), str0);

// Normarlize JP to fullWidth and alphaNumberic to halfWidth
const normarlizeSearchKeyword = str => {
    if (!str) return null;
    if (str.length === 0) return '';
    return toFullWidth(str) // all to full width
        .replace(
            // only alphanumberic to halfwidth
            /[\uff01-\uff5e]/g,
            function(ch) {
                return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
            }
        );
};

export const sortListBookmarkByNameASC = list => {
    if (Utils.isEmptyArray(list)) {
        return [];
    }
    return list.sort((a, b) => {
        let nameA = a.name;
        let nameB = b.name;
        return nameA.localeCompare(nameB);
    });
};

export const sortListByNameDictionaryASC = list => {
    if (Utils.isEmptyArray(list)) {
        return [];
    }
    return list.sort((a, b) => {
        let nameA = Utils.getTextByLanguage(Constant.LANGUAGE_TYPE.JP, a.name);
        let nameB = Utils.getTextByLanguage(Constant.LANGUAGE_TYPE.JP, b.name);
        return nameA.localeCompare(nameB);
    });
};

export const sortListByCorporationNameDictionartDESC = list => {
    if (Utils.isEmptyArray(list)) {
        return [];
    }
    return list.sort((a, b) => {
        let nameA = a.corporationName;
        let nameB = b.corporationName;
        return nameB.localeCompare(nameA);
    });
};

export const convertByteToGigaByte = (byte, numberDecimal) => {
    if (Utils.isEmptyString(byte)) return;
    if (
        Utils.isEmptyString(numberDecimal) ||
        !Number.isInteger(numberDecimal) ||
        (Number.isInteger(numberDecimal) && numberDecimal < 0)
    ) {
        return parseFloat(byte / (1024 * 1024 * 1024));
    }
    return parseFloat((byte / (1024 * 1024 * 1024)).toFixed(numberDecimal));
};

export const sortListByLabelDictionaryASC = (list, sortField = '') => {
    if (Utils.isEmptyArray(list)) {
        return [];
    }
    if (!sortField) {
        sortField = 'label';
    }
    return list.sort((a, b) => {
        let nameA = a[sortField];
        let nameB = b[sortField];
        return nameA.localeCompare(nameB);
    });
};

export const sortFullNameUserByDictionaryASC = list => {
    if (Utils.isEmptyArray(list)) {
        return [];
    }
    return list.sort((a, b) => {
        let nameA = getFullNameOfUser(Constant.LANGUAGE_TYPE.JP, a);
        let nameB = getFullNameOfUser(Constant.LANGUAGE_TYPE.JP, b);
        return nameA.localeCompare(nameB);
    });
};

export const sortListByNameCorpDictionaryASC = list => {
    if (Utils.isEmptyArray(list)) {
        return [];
    }
    return list.sort((a, b) => {
        let nameA = a?.title;
        let nameB = b?.title;
        return nameA.localeCompare(nameB);
    });
};

export const sortListByTitle = list => {
    if (Utils.isEmptyArray(list)) {
        return [];
    }
    let listClone = cloneDeep(list)
    return listClone.sort((a, b) => {
        let nameA = a?.title;
        let nameB = b?.title;
        return nameA.localeCompare(nameB);
    });
};

export const sortListTagFiledOfCategory = listTagField => {
    return listTagField.sort((tagA, tagB) => {});
};

export const escapeHtmlCharacters = htmlStr => {
    return htmlStr
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');
};

export const giveBackHtmlCharacters = htmlStr => {
    return htmlStr
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");
};

export const isPublicDesk = project => {
    if (isEmptyObject(project)) return;

    return (
        project.type === Constant.PROJECT_TYPE.GENERAL &&
        project.isPublic &&
        project.isDesk &&
        !project.isGuest
    );
};

export const isPrivateDesk = project => {
    if (isEmptyObject(project)) return;

    return (
        project.type === Constant.PROJECT_TYPE.GENERAL &&
        !project.isPublic &&
        project.isDesk &&
        !project.isGuest
    );
};

export const isPublicOffDeskOff = project => {
    if (isEmptyObject(project)) return;

    return (
        project.type === Constant.PROJECT_TYPE.GENERAL &&
        !project.isPublic &&
        !project.isDesk &&
        !project.isGuest
    );
};

export const isProjectGuest = project => {
    if (isEmptyObject(project)) return;

    return (
        project.type === Constant.PROJECT_TYPE.GENERAL &&
        !project.isPublic &&
        !project.isDesk &&
        project.isGuest
    );
};

export const isProjectGuestDesk = project => {
    if (isEmptyObject(project)) return;

    return (
        project.type === Constant.PROJECT_TYPE.GENERAL &&
        !project.isPublic &&
        project.isDesk &&
        project.isGuest
    );
};

export const isGuestUser = userDetail => {
    if (isEmptyObject(userDetail) || isEmpty(userDetail)) {
        return false;
    }
    if (userDetail.type === Constant.USER_TYPE.GENERAL && userDetail.isGuest) {
        return true;
    }
    return false;
};

export const isGuestContractType = org => {
    if (!Utils.isEmptyObject(org)) {
        if (
            org.contractType === Constant.CORPORATION_CONTRACT_TYPE.GUEST ||
            org.contractType === Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK
        ) return true;
        return false;
    }
    return false;
}

export const isGeneralUserWithoutGuest = userDetail => {
    if (isEmptyObject(userDetail) || isEmpty(userDetail)) {
        return false;
    }
    if (userDetail.type === Constant.USER_TYPE.GENERAL && !userDetail.isGuest) {
        return true;
    }
    return false;
};

export const isGeneralUser = userDetail => {
    if (isEmptyObject(userDetail) || isEmpty(userDetail)) {
        return false;
    }
    if (userDetail.type === Constant.USER_TYPE.GENERAL) {
        return true;
    }
    return false;
};

export const isB2CUser = userDetail => {
    if (isEmptyObject(userDetail) || isEmpty(userDetail)) {
        return false;
    }
    if (userDetail.type === Constant.USER_TYPE.CUSTOMER) {
        return true;
    }
    return false;
};

export const getPanelData = (panelId, panelList) => {
    if (!panelId || !panelList || isEmptyArray(panelList)) return;
    return panelList.find(panel => panel.id === panelId);
};

const filterDuplicateItemsOfArray = (array, checkDuplicateFunction) => {
    let listItemUnique = [];
    array?.forEach(item => {
        if (!checkDuplicateFunction(listItemUnique, item)) {
            listItemUnique.push(item);
        }
    });
    return listItemUnique;
};

const checkArrayIncludesItem = (array, item) => {
    return array?.includes(item);
};

/**
 * removeDuplicateInArrayById
 * @param {*} array
 * @returns
 */
const removeDuplicateInArrayById = array => {
    if (isEmptyArray(array)) return [];

    let newArray = array.filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id)
    );

    return newArray;
};

const upperCaseFirstLetter = string => `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;

const lowerCaseAllWordsExceptFirstLetters = string =>
    string.replaceAll(/\S*/g, word => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`);
const compareTwoArraySorted = (array1, array2) => {
    return array1.join('') === array2.join('');
};

/**
 * getUserNameWithActiveStatus
 * @param {*} userDetail
 * @returns
 */
const getUserNameWithActiveStatus = (userDetail, returnMailIfEmpty = false) => {
    if (isEmptyObject(userDetail)) return '';

    let userName = getFullNameOfUser(Constant.LANGUAGE_TYPE.JP, userDetail);
    if (userName) {
        userName = userName.trim();
    }
    if (isEmptyString(userName) && returnMailIfEmpty && userDetail.mail) {
        userName = Utils.getDisplayUserEmailWithoutSuffix(userDetail.mail);
    }
    if (userDetail.hasOwnProperty('active') && userDetail.active === Constant.ACTIVE_FLAG.DELETED) {
        userName = `${userName} (${Constant.SUFFIX_TEXT.DELETED_USER})`;
    }

    return userName;
};

/**
 * getCorporationNameSuffixText
 * @param {*} corporationDetail
 * @returns
 */
const getCorporationNameSuffixText = corporationDetail => {
    let suffixText = '';
    if (!corporationDetail || isEmptyObject(corporationDetail)) {
        return suffixText;
    }

    if (corporationDetail.active === Constant.CORPORATION_ACTIVE.BLOCK) {
        suffixText = Constant.SUFFIX_TEXT.BLOCK_CORPORATION;
    }
    if (corporationDetail.active === Constant.CORPORATION_ACTIVE.DELETED) {
        suffixText = Constant.SUFFIX_TEXT.DELETED_CORPORATION;
    }

    return suffixText;
};

const getFileExtension = filename => {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
};

const updateNewDisplayNameOfProject = (project, newName) => {
    if (isEmptyObject(project) || isEmptyString(newName)) {
        return project;
    }
    let projectUpdate = { ...project };

    let newNameUpdate = {
        jp: {
            A: 'F',
            text: newName
        }
    };
    projectUpdate.name = JSON.stringify(newNameUpdate);

    return projectUpdate;
};

// using fetch api to control error
const handleRequest = promise => {
    return promise.then(response => [undefined, response]).catch(err => [err, undefined]);
};
const getTitle = settingTitle => {
    let title = '';
    settingTitle?.forEach(element => {
        if (!element.isBreak) {
            title = title + element.text + ' ';
        }
    });
    return title;
};

const getDateTimeByFormatHHMM = dateTimeString => {
    let dateTime = new Date(dateTimeString);
    let hours = dateTime.getHours() < 10 ? `0${dateTime.getHours()}` : dateTime.getHours();
    let minutes = dateTime.getMinutes() < 10 ? `0${dateTime.getMinutes()}` : dateTime.getMinutes();

    return `${hours}:${minutes}`;
};

const sortListTagFieldOfCategory = listTagField => {
    return listTagField.sort((a, b) => {
        if (a.priority && !b.priority) return -1;
        if (!a.priority && b.priority) return 1;
        return a.order - b.order;
    });
};

const sortCategoriesByOrder = listCategories => {
    return listCategories.sort((categoryA, categoryB) => {
        return categoryA.order - categoryB.order;
    });
};

const generateChatBotScript = projectId => {
    if (!projectId) return;
    return `<script id="sdesk-chatbot" src="${process.env.REACT_APP_CLOUD_FRONT_URL}/assets/chatbot.js?projectId=${projectId}" type="text/javascript"></script>`;
};

const isAnonymousQAWaitingTicketFromLiveChat = ticket => {
    if (
        ticket.anonymousUserInfo?.type === Constant.ANONYMOUS_TYPE.CHATBOT_LIVECHAT &&
        ticket.anonymousQaStatus === Constant.ANONYMOUS_TICKET_STATUS.WAITING_APPROVE
    ) {
        return true;
    }
    return false;
};

const initKeyFileSaveLogCreateTicketByUploadCsv = (
    corporationId,
    projectId,
    userId,
    uploadKey,
    hasPrefix = false
) => {
    if (!corporationId || !projectId || !userId || !uploadKey) return;
    let keyFile = `${corporationId}/uploadCsvLog/${projectId}/${userId}/${uploadKey}.log`;
    if (hasPrefix) {
        keyFile = `public/${keyFile}`;
    }
    return keyFile;
};

const renderUploadKeys = fileName => {
    if (!fileName) return;

    let timeStamp = Date.now().toString();
    return `${fileName}_${timeStamp}`;
};

const getPaginatedItems = (items, page, pageSize) => {
    var pg = page || 1,
        pgSize = pageSize || Constant.DEFAULT_PAGE_SIZE,
        offset = (pg - 1) * pgSize,
        pagedItems = drop(items, offset).slice(0, pgSize);
    return {
        page: pg,
        pageSize: pgSize,
        total: items.length,
        total_pages: Math.ceil(items.length / pgSize),
        data: pagedItems
    };
};

const mappingLogStatusText = (status, isNg = false) => {
    let statusText,
        statusClass = '';
    switch (status) {
        case UserLogConstant.LOG_STATUS.INPROGRESS:
            statusText = I18n.get('KNOWCRLB033');
            statusClass = 'warning';
            break;
        case UserLogConstant.LOG_STATUS.OK:
            statusText = I18n.get('KNOWCRLB030');
            statusClass = 'success';
            if (isNg) {
                statusText = I18n.get('KNOWCRLB031');
                statusClass = 'warning';
            }
            break;
        case UserLogConstant.LOG_STATUS.NG:
            statusText = I18n.get('KNOWCRLB031');
            statusClass = 'warning';
            break;
        case UserLogConstant.LOG_STATUS.ERROR:
            statusText = I18n.get('KNOWCRLB032');
            statusClass = 'danger';
            break;
        default:
            break;
    }

    return { statusText, statusClass };
};

const mappingLogMessageByStatus = (status, line, totalLine, messageError, isNg = false, deleteMode = {}) => {
    let message = '';
    let mappingStatus = status;
    if (!Utils.isEmptyObject(deleteMode)) {
        mappingStatus = deleteMode.isSuccess
            ? UserLogConstant.LOG_STATUS.MODE_DELETE_OK
            : UserLogConstant.LOG_STATUS.MODE_DELETE_ERROR
    }

    switch (mappingStatus) {
        case UserLogConstant.LOG_STATUS.INPROGRESS:
            message = `${line}/${totalLine}${I18n.get('KNOWCRMSG012')}`;
            break;
        case UserLogConstant.LOG_STATUS.OK:
            const description = isNg ? I18n.get('KNOWCRMSG010') : I18n.get('KNOWCRMSG009');
            message = `${line}/${totalLine}${description}`;
            break;
        case UserLogConstant.LOG_STATUS.ERROR:
            message = `${line}/${totalLine}行目は${I18n.get(`${messageError}`)}${I18n.get(
                'KNOWCRMSG011'
            )}`;
            break;
        case UserLogConstant.LOG_STATUS.MODE_DELETE_OK:
            message = I18n.get('MSG-KNOW-LOG-DELETE-KNOWLEDGE-TICKET-SUCCEEDED')
                .replace('{{processed_line}}', line)
                .replace('{{all_line}}', totalLine)
            break;
        case UserLogConstant.LOG_STATUS.MODE_DELETE_ERROR:
            message = I18n.get('MSG-KNOW-LOG-DELETE-KNOWLEDGE-TICKET-FAILED')
                .replace('{{processed_line}}', line)
                .replace('{{all_line}}', totalLine)
            break;
        default:
            break;
    }

    return message;
};

const mappingLogErrorList = errorList => {
    if (!errorList?.length) return [];
    errorList = errorList?.filter(error => error?.msg !== 'MSG-KNOW-LOG-AUTO-CREATE-TAG-ITEM-SUCCEEDED')
    return errorList.map(error => {
        return `${error?.fieldName} : ${I18n.get(`${error?.msg}`)}`;
    });
};

const mappingLogSuccessList = errorList => {
    if (!errorList?.length) return [];
    errorList = errorList?.filter(error => error?.msg === 'MSG-KNOW-LOG-AUTO-CREATE-TAG-ITEM-SUCCEEDED')
    return errorList.map(error => {
       return `${error?.fieldName}${I18n.get(`${error?.msg}`)}`;
    });
}

/**
 * 
 * @param {string} corporationId 
 * @param {string} projectId 
 * @param {number} version 
 * @param {Constant.KEY_FILE_CSS_TYPE} type Constant.KEY_FILE_CSS_TYPE.PUBLIC_KNOWLEDGE_SITE: pks, Constant.KEY_FILE_CSS_TYPE.KNOWLEDGE_ROBO: robo.  it used to pks ago. So it's 0 default. 
 * @returns 
 */
const genKeyFileCss = (corporationId, projectId, version, type = Constant.KEY_FILE_CSS_TYPE.PUBLIC_KNOWLEDGE_SITE) => {
    if (!corporationId || !projectId || !version) return;
    let keyFile = '';
    switch (type) {
        case Constant.KEY_FILE_CSS_TYPE.KNOWLEDGE_ROBO:
            keyFile = `assets/${corporationId}/${projectId}/style/knowledge-robo-v${version}.css`;
            break;
        case Constant.KEY_FILE_CSS_TYPE.PUBLIC_KNOWLEDGE_SITE:
        default:
            keyFile = `assets/${corporationId}/${projectId}/style/public-knowledge-v${version}.css`;
            break;
    }
    return keyFile;
};

const randomSubDomainCorporation = () =>{
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 8; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

const getUrlLinkCorporation = (contractType, subDomain) => {
    // LAST_CORP_DOMAIN
    let corporationDomain = localStorageUtil.getCorporationDomain()
        ? localStorageUtil.getCorporationDomain()
        : localStorageUtil.getLastLoginCorpDomain();

    let urlAfterLogout = `/login/${corporationDomain}`;
    
    if(!contractType || !subDomain) {
        return urlAfterLogout;
    }

    switch (contractType) {
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST:
            urlAfterLogout = `/login/${subDomain}/c/${localStorageUtil.getCorporationDomain()}`
            break;
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK:
            urlAfterLogout = `/login/${subDomain}/p/${localStorageUtil.getCorporationDomain()}`
            break;
        default:
            urlAfterLogout = `/login/${corporationDomain}`;
            break;
    }

    return urlAfterLogout;
}

const getUrlLinkCorporationSignedIn = (contractType, subDomain) => {
    let urlAfterSignedIn = `/${localStorageUtil.getCorporationDomain()}`;
    
    if(!contractType || !subDomain) {
        return urlAfterSignedIn;
    }

    switch (contractType) {
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST:
            urlAfterSignedIn = `/${subDomain}/c/${localStorageUtil.getCorporationDomain()}`
            break;
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK:
            urlAfterSignedIn = `/${subDomain}/p/${localStorageUtil.getCorporationDomain()}`
            break;
        default:
            urlAfterSignedIn = `/${localStorageUtil.getCorporationDomain()}`;
            break;
    }

    return urlAfterSignedIn;
}

const getUrlLinkShareLinkTicket = (subDomain, projectId, ticketId) => {
    if (!subDomain || !projectId || !ticketId) return '';
    return `share/${subDomain}/${projectId}/ticket/${ticketId}`;
};

const getTicketIdFromSharedLink = url => {
    const regex = /\/ticket\/([^\/]+)\/?$/;
    const match = url.match(regex);
    if (match) {
        const ticketId = match[1];
        return ticketId;
    }
    return false;
};

const getChatPermissionOfTicket = (ticketDetail) => {
    const chatPermission = ticketDetail.chatPermission;
    if(chatPermission === Constant.KNOWLEDGE_CHAT_PERMISSION.BY_VIEW_PERMISSION){
        return {
            isByViewPermission : true,
            permission: ticketDetail.viewPermission
        }
    }else{
       return{ 
        isByViewPermission : false,
        permission: ticketDetail.chatPermission
       }
    }
}

const getCurrentUrlCaseLogin = (orgCode) => {
    let corporationDomain = localStorageUtil.getLastLoginCorpDomain();
    let contracTypeAndSubDomain = localStorageUtil.getContractTypeAndSubDomainCorp();
    contracTypeAndSubDomain = convertJSONDataToObject(contracTypeAndSubDomain);

    if(isEmptyObject(contracTypeAndSubDomain)) return `/${corporationDomain}`;
    
    let subDomainInvite = contracTypeAndSubDomain.subDomainInvite;
    let urlLink = corporationDomain ? corporationDomain : orgCode;

    switch (contracTypeAndSubDomain.contractType) {
        case Constant.CORPORATION_CONTRACT_TYPE.PREMIUM:
            return `/${urlLink}`;
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST:
            return `/${subDomainInvite}/c/${urlLink}`;
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK:
            return `/${subDomainInvite}/p/${urlLink}`;
        default:
            return `/${corporationDomain}`;
    }
}

const removeExcessZeros = input => {
    try {
        if (Utils.isEmptyString(input)) {
            return input;
        }

        const number = parseFloat(input);
        const isDecimal = /^[0-9]+\.[0-9]+$/;

        if (!isDecimal.test(number) && number !== 0) {
            const trimmedNumberString = input.replace(/^0+/, '');
            return trimmedNumberString;
        }
        return number.toString();
    } catch (err) {
        return input;
    }
};

const convertDecimalNumber = (convertNumber, fixed) =>  {
    if (Number.isFinite(convertNumber) && !Number.isInteger(convertNumber)) {
      return convertNumber.toFixed(fixed).replace(/\.?0+$/, '');
    } else {
      return convertNumber
    }
}

const sortListByOrderDesc = array => {
    if (Utils.isEmptyArray(array)) return [];
  
    return array.sort((a, b) => {
      const orderComparison = parseInt(b.order) - parseInt(a.order);
  
      if (orderComparison !== 0) {
        return orderComparison;
      } else {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      }
    });
}

function isNumber(value) {
    return /^\d+$/.test(value);
}

const checkNumberDecimal = (inputValue) => {
    const regexDecimal = /^[0-9]+(\.[0-9]+)?$/;
    if (!regexDecimal.test(inputValue)) {
        return I18n.get('MSG-CM-WRONG-NUMBER-DOT-FORMAT');
    }
    if(inputValue.startsWith('0') && !inputValue.includes('.')) {
        return I18n.get('MSG-CM-STORAGE-ERROR');
    }
    if(Utils.removeExcessZeros(inputValue) === "0") {
        return I18n.get('MSG-CM-STORAGE-ERROR');
    }
    const [integerPart, decimalPart] = inputValue.split('.');
    if (
        (!Utils.isEmptyString(integerPart) && integerPart.length > 4) ||
        (!Utils.isEmptyString(decimalPart) && decimalPart.length > 3)
    ) {
        return I18n.get('MSG-CM-STORAGE-ERROR');
    }

    return '';
}

const convertNumber = (number, toFixed = 0) => {
    const isDecimal = number.split('.');
    let newNumber = number;

    if (isDecimal.length > 1) {
        newNumber = convertDecimalNumber(number, toFixed);
    }

    if (number.startsWith('0') && isNumber(number)) {
        newNumber = BigInt(number).toString();
    }

    return newNumber;
}

const  areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    }

    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    for (let i = 0; i < array1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
            return false;
        }
    }

    return true;
}

const getViewPermissionLabel = (viewPermission) => {

    const ONLY_CREATOR_PERMISSION_ITEM = {
        displayName: '投稿者',
        value: Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.CREATOR_ONLY
    };
    
    const IN_PROJECT_PERMISSION_ITEM = {
        displayName: 'プロジェクト内',
        value: Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.IN_PROJECT
    };
    
    const IN_ORG_PERMISSION_ITEM = {
        displayName: '組織内',
        value: Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.IN_ORG
    };
    
    const B2B_CUSTOMER_PERMISSION_ITEM = {
        displayName: '全B2Bカスタマープロジェクト',
        value: Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.B2B_CUSTOMER
    };
    
    const B2B_SUPPORT_PERMISSION_ITEM = {
        displayName: '全B2Bサポートプロジェクト',
        value: Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.B2B_SUPPORT
    };
    
    const PUBLIC_PERMISSION_ITEM = {
        displayName: '一般公開',
        value: Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.PUBLIC
    };

    let listViewPermissionLabel = [];

    viewPermission.forEach(permission => {
        switch (permission) {
            case ONLY_CREATOR_PERMISSION_ITEM.value:
                listViewPermissionLabel.push(ONLY_CREATOR_PERMISSION_ITEM.displayName)
                break;
                case IN_PROJECT_PERMISSION_ITEM.value:
                    listViewPermissionLabel.push(IN_PROJECT_PERMISSION_ITEM.displayName)
                break;
                case IN_ORG_PERMISSION_ITEM.value:
                    listViewPermissionLabel.push(IN_ORG_PERMISSION_ITEM.displayName)
                break;
                case B2B_CUSTOMER_PERMISSION_ITEM.value:
                    listViewPermissionLabel.push(B2B_CUSTOMER_PERMISSION_ITEM.displayName)
                break;
                case B2B_SUPPORT_PERMISSION_ITEM.value:
                    listViewPermissionLabel.push(B2B_SUPPORT_PERMISSION_ITEM.displayName)
                break;
                case PUBLIC_PERMISSION_ITEM.value:
                    listViewPermissionLabel.push(PUBLIC_PERMISSION_ITEM.displayName)
                break;
        
            default:
                break;
        }
    })

    return listViewPermissionLabel
}

const isDisabledTicketExternalWhenDeskServiceOff = (ticket, corporation) => {
    if (
        corporation.deskService === Constant.STATUS_SERVICE_PERMISSION.OFF &&
        ticket.isExternalTicket &&
        (ticket.ticketType === Constant.TICKET_TYPE.BROADCAST_PARENT ||
            ticket.ticketType === Constant.TICKET_TYPE.DELEGATE)
    ) {
        return true;
    }
    return false;
};

const checkUserPermission = (corpData, projectData) => {
    let userPermission = ''
    if (isEmptyObject(corpData) || isEmptyObject(projectData)) return userPermission;

    const contractType = corpData.contractType;
    switch (contractType) {
        case Constant.CORPORATION_CONTRACT_TYPE.PREMIUM:
            if (projectData.type === Constant.PROJECT_TYPE.GENERAL) {
                userPermission = Constant.USER_PERMISSION_VIEW_KNOWLEDGE.MEMBER_OF_PROJECT;
            }
            if (projectData.type === Constant.PROJECT_TYPE.BASE) {
                userPermission = Constant.USER_PERMISSION_VIEW_KNOWLEDGE.PERSONAL_PROJECT;
            }
            if (projectData.type === Constant.PROJECT_TYPE.CUSTOMER) {
                userPermission = Constant.USER_PERMISSION_VIEW_KNOWLEDGE.B2C;
            }
            break;
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST:
            userPermission = Constant.USER_PERMISSION_VIEW_KNOWLEDGE.GUEST_SUPPORTED;
            break;
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK:
            userPermission = Constant.USER_PERMISSION_VIEW_KNOWLEDGE.GUEST_PARTNER;
            break;
        default:
            break;
    }
    return userPermission;
}

const getProjectName = (project) => {
    let projectName = convertJSONDataToObject(project.name);
    projectName = projectName ? projectName['jp']?.text : '';
    return projectName;
}

function arraysHaveSameElements(arr1, arr2) {
    return arr1.length === arr2.length && arr1.every(element => arr2.includes(element));
}

const isDisplaySplitPdfSwitch = (corporationDetail)=>{
    let isDisplay = false;

    if (Utils.isGuestContractType(corporationDetail)) {
        const inviterSettingObject = convertJSONDataToObject(corporationDetail.inviterSetting)
        if (inviterSettingObject.convertPDF === Constant.STATUS_SERVICE_PERMISSION.ON) isDisplay = true;
    } else {
        if (corporationDetail.limitValue?.convertPDF === Constant.STATUS_SERVICE_PERMISSION.ON) isDisplay = true;
    }
    return isDisplay
} 

const isNumeric = (num) => (typeof(num) === 'number' || typeof(num) === "string" && num.trim() !== '') && !isNaN(num);

function convertLatinNumberToJapaneseFullWidthNumber(latinNumber) {
    const latinDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const fullWidthJapaneseNumerals = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];

    const japaneseFullWidthNumber = latinNumber
        .toString()
        .split('')
        .map(digit => fullWidthJapaneseNumerals[latinDigits.indexOf(digit)])
        .join('');

    return japaneseFullWidthNumber;
}

function removeDigitsFromStringsArray(arr) {
    if (!arr || isEmptyArray(arr)) return [];

    for (let i = 0; i < arr.length; i++) {
      arr[i] = removeLatinAndJapaneseFullwidthNumbers(arr[i]);
    }
    
    return arr;
}

function removeLatinAndJapaneseFullwidthNumbers(input) {
    const regex = /[0-9０-９]/g;
    const result = input.replace(regex, '');
  
    return result.trim() || '';
}

function isJapaneseFullwidthNumber(text) {
    const japaneseFullwidthNumbers = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];

    for (let i = 0; i < text.length; i++) {
        if (!japaneseFullwidthNumbers.includes(text[i])) {
            return false;
        }
    }

    return true;
}

function convertJapaneseFullWidthNumberToLatinNumber(japaneseFullWidthNumber) {
    const latinDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const fullWidthJapaneseNumerals = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];

    const latinNumber = japaneseFullWidthNumber
        .toString()
        .split('')
        .map(digit => latinDigits[fullWidthJapaneseNumerals.indexOf(digit)])
        .join('');

    return latinNumber;
}

function isNameOrUrlOfPdfFile (url) {
    const pdfFileExtension = '.pdf'

    if(!url) return false
    return String(url).toLocaleLowerCase().endsWith(pdfFileExtension)
}

async function isUsingFunctionService (
    viewKnowledgeProjectSelected,
    corporationDetail,
    functionKey='',
    setUsingFunction
) {
    if (
        isEmptyObject(viewKnowledgeProjectSelected)
        || isEmptyString(functionKey)
        || isEmptyObject(corporationDetail)
    ) return false;

    let inviterSettingKey = functionKey;
    if (functionKey === 'knowledgeService') {
        inviterSettingKey = 'usingKnowledgeService'
    }

    if (
        viewKnowledgeProjectSelected.corporationId !== corporationDetail.id &&
        viewKnowledgeProjectSelected?.inviter !== corporationDetail.id
    ) {
        const corpRootBySelectedProject = await getCorporationById(null, viewKnowledgeProjectSelected.corporationId);
        if (!isEmptyObject(corpRootBySelectedProject)) {
            const limitValue = { ...corpRootBySelectedProject.limitValue };
            const newIsUsingFunction = limitValue?.[functionKey] === Constant.STATUS_SERVICE_PERMISSION.ON;
            setUsingFunction(newIsUsingFunction);
        }
        
    } else {
        if ([Constant.CORPORATION_CONTRACT_TYPE.GUEST, Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK].includes(corporationDetail.contractType)) {
            const newIsUsingFunction = convertJSONDataToObject(corporationDetail.inviterSetting)?.[inviterSettingKey] === Constant.STATUS_SERVICE_PERMISSION.ON;
            setUsingFunction(newIsUsingFunction);
        } else {
            const newIsUsingFunction = corporationDetail.limitValue?.[functionKey] === Constant.STATUS_SERVICE_PERMISSION.ON;
            setUsingFunction(newIsUsingFunction);
        }
    }
}


const setDefaultPermissionForSynonymForm = (formDetail) => {
    if (!formDetail) return {};

    let newFormDetail = {...formDetail};
    newFormDetail.viewPermission = [
        Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.CREATOR_ONLY,
        Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.IN_PROJECT
    ]
    newFormDetail.editPermission = [
        Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.CREATOR_ONLY,
        Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.PROJECT_MANAGER
    ]
    newFormDetail.createTicketForOwnerPermission = [
        Constant.KNOWLEDGE_VIEW_EDIT_PERMISSION.IN_PROJECT
    ]
    newFormDetail.additionalCreateTicketForOwnerPermission = []
    newFormDetail.chatPermission = Constant.KNOWLEDGE_CHAT_PERMISSION.IN_PROJECT
    return newFormDetail;
}

//validate max size kbs;
const validateSizeOfFieldTicket = (value, maxSize = Constant.MAX_SIZE_KB_FIELD) => {
    let errorMessage = ''
    if(!value) return errorMessage;

    const encoder = new TextEncoder();
    const utf8Bytes = encoder.encode(value);
    const sizeInKB = utf8Bytes.length / 1024;

    if(sizeInKB > maxSize) {
        errorMessage = I18n.get('MSG-CM-OVER-MAX-CAPACITY').replace('{{max_capacity}}', '350KB');
    }
    return errorMessage;
}

const isValidLinkPromptInput = link => {
    let appDomain = process.env.REACT_APP_DOMAIN_NAME || '';

    if (appDomain.endsWith('/')) {
        appDomain = appDomain.slice(0, -1);
    }
    const urlRegex = new RegExp(
        `^${appDomain}\/share\/[a-z0-9-]+\/project_[a-z0-9-]+\/ticket\/knowledge_[0-9a-z-]+$`
    );


    return urlRegex.test(link.trim());
};

const destructLinkPrompt = linkInput => {
    if(!linkInput) return '';
    
    const destructLink = linkInput.split('/');
    if (destructLink.length !== 8) {
        return '';
    }

    const uriTicketId = destructLink[7];
    return uriTicketId
};

const createTagForLogKibana = (drillDownDataSelected) => {
    let listTagSuggest = [];
    if (drillDownDataSelected.length > 0) {
        for (let i = 0; i < drillDownDataSelected.length; i++) {
            let fieldLabel = drillDownDataSelected[i].fieldLabel;
            let itemLabel = drillDownDataSelected[i].itemLabel;
            listTagSuggest.push( itemLabel + '@' + fieldLabel);
        }
    }
    return listTagSuggest;
}

const uploadFileWithRetry = async (fileKey, fileContent, maxRetries) => {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        const file = new Blob([fileContent], { type: 'text/css' });
        await Storage.put(fileKey, file, {
            acl: "public-read",
            level: 'public',
            contentType: file.type
        });
        
        return;
      } catch (error) {
        console.error('Error uploading file:', error);
        retries++;
        console.log(`Retry attempt ${retries}`);
      }
    }
    
    console.error('Max retries exceeded. Unable to upload file.');
};

export const validateStyleSheet = value => {
    if(Utils.isEmptyString(value.trim())) return 'COMMSG014';
    if (value.length > 200000) return 'MSG-CM-OVER-MAXLENGTH';

    return '';
};

const isValidUrl = (url) => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
};

export const getShareLinkUrls = (content) => {
    const text = `${content}`;
    const urlRegex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
    const urls = text.match(urlRegex) || [];
    if (Utils.isEmptyArray(urls)) return [];

    let validUrls = urls.filter(url => {
        if (!isValidUrl(url)) return false;

        // const urlDomain = new URL(url).origin;
        const urlPathName = new URL(url).pathname;
        const matchUrl = matchPath(urlPathName, {
            path: Constant.SHARE_TICKET_ROUTER,
            exact: true,
            strict: true
        });
        return !Utils.isEmptyObject(matchUrl) ? matchUrl.isExact : false
    });
    // console.log('getShareLinkUrls :>> ', validUrls);
    return validUrls;
};
const convertByteToMB  = (bytes)=>{
    return Number(bytes) / (1024 * 1024) // 1MB = 1024 kilobyte = 1024 * 1024 byte
}

const splitArrayIntoSubArrays = (array, chunkSize) => {
    return array.reduce((acc, _, index) => {
        if (index % chunkSize === 0) {
            acc.push(array.slice(index, index + chunkSize));
        }
        return acc;
    }, []);
};

const sortByExecuteTimeAtDesc = arr => {
    if (isEmptyArray(arr)) {
        return [];
    }
    return arr.sort((a, b) => {
        return new Date(b.executeTime) - new Date(a.executeTime);
    });
};

const generateRandomStr = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let str = '';
    for (let i = 0; i < 30; i++) {
        str += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return str;
};

const getUniqueObjectListInArray = (objectsArray) => {
    if (isEmptyArray(objectsArray)) return [];
    let uniqueObjectsArray = [];
    let uniqueObjectsSet = new Set();

    objectsArray.forEach(obj => {
        let jsonString = JSON.stringify(obj);
        if (!uniqueObjectsSet.has(jsonString)) {
            uniqueObjectsSet.add(jsonString);
            uniqueObjectsArray.push(obj);
        }
    });

    return uniqueObjectsArray;
}

const filterDuplicates = (arr)=> {
    // Sử dụng Set để lọc các phần tử duy nhất
    let uniqueItems = new Set(arr);

    // Chuyển đổi Set thành mảng bằng cách sử dụng spread operator
    let uniqueArray = [...uniqueItems];

    return uniqueArray;
}

const initKeyFileSaveLogBulkAction = (
    corporationId,
    userId,
    fileName,
    logTicketType = Constant.BULK_ACTION_LOG_TICKET_TYPE.KNOWLEDGE,
    projectId
) => {
    let s3Key = '';

    switch (logTicketType) {
        case Constant.BULK_ACTION_LOG_TICKET_TYPE.KNOWLEDGE:
            s3Key = `${corporationId}/${projectId}/BulkActionLog/${userId}/${fileName}`;
            break;
        case Constant.BULK_ACTION_LOG_TICKET_TYPE.HELPDESK:
            s3Key = `${corporationId}/${projectId}/BulkActionLog/helpdesk/${userId}/${fileName}`;
            break;
        default:
            return '';
    }

    return s3Key;
};

const isAnonymousQATicketFromChatBotAndFinish = ticket => {
    if (ticket?.anonymousUserInfo?.type === Constant.ANONYMOUS_TYPE.CHATBOT) {
        return true;
    }
    return false;
};

const isAnonymousQATicketFromIframe = ticket => {
    if (ticket?.anonymousUserInfo?.type === Constant.ANONYMOUS_TYPE.IFRAME || 
        (ticket?.customer === Constant.ANONYMOUS_USER_ID && !isAnonymousQATicketFromChatBotAndFinish(ticket) && !isAnonymousQATicketFromLiveChat(ticket))
    ) {
        return true;
    }
    return false;
};

const isStringOverLength = (myString, size = 50) => {
    return myString?.length > size;
};

const getCurrentDevice = () => {
    if (window.innerWidth >= Constant.PANEL_RESPONSIVE.PC) {
        return Constant.SCREEN_DEVICE.DESKTOP
    }
    if (window.innerWidth >= Constant.PANEL_RESPONSIVE.MOBILE && window.innerWidth < Constant.PANEL_RESPONSIVE.PC) {
        return Constant.SCREEN_DEVICE.TABLET;
    } 

    return Constant.SCREEN_DEVICE.MOBILE;
}

/**
 * delay function
 * @param {*} ms 
 * @returns 
 */
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const getArrByBreakline = (string) => {
    if (isEmptyString(string)) return [];
    return string.split(Constant.BREAKLINE_REGEX);
}

const validIpAddressFormat = (ip) => {
    if (isEmptyString(ip)) return false;
    return Constant.IPV4_FORMAT_REGEX.test(ip);
}

const isNumberExludeStartZero = (str) => {
    if (isEmptyString(str)) return false;
    if (!Constant.NUMBER_REGEX.test(str)) return false;
    if (str.startsWith('0')) return false;
    return true;
}

const findDuplicateData = arr => arr.filter((item, index) => arr.indexOf(item) !== index);

const isEnableCreateEmailTicketMode = (corporationDetail, projectInuseDetail) => {
    if (isEmptyObject(corporationDetail) || isEmptyObject(projectInuseDetail)) return false;
    let mailSetting = projectInuseDetail?.mailSetting ? convertJSONDataToObject(projectInuseDetail?.mailSetting) : {};
    let isCorporationOnEmailTicketMode = false;
    let isProjectOnEmailTicketMode = mailSetting?.isEmailResponseSettingFunction;

    if([Constant.CORPORATION_CONTRACT_TYPE.GUEST,Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK].includes(corporationDetail.contractType)){
        const inviterSetting = convertJSONDataToObject(corporationDetail.inviterSetting);
        isCorporationOnEmailTicketMode = inviterSetting.emailResponseFunction;
    } else {
        isCorporationOnEmailTicketMode = corporationDetail?.limitValue?.emailResponseFunction
    }
    
    let isOnEmailTicketMode = isCorporationOnEmailTicketMode && isProjectOnEmailTicketMode && isPublicDesk(projectInuseDetail);

    return isOnEmailTicketMode ? true : false;
}


const isEnableCreateEmailTicketModeOnSkipProjectType = (corporationDetail, projectInuseDetail) => {
    if (isEmptyObject(corporationDetail) || isEmptyObject(projectInuseDetail)) return false;
    let mailSetting = projectInuseDetail?.mailSetting ? convertJSONDataToObject(projectInuseDetail?.mailSetting) : {};
    let isCorporationOnEmailTicketMode = false;
    let isProjectOnEmailTicketMode = mailSetting?.isEmailResponseSettingFunction;

    if([Constant.CORPORATION_CONTRACT_TYPE.GUEST,Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK].includes(corporationDetail.contractType)){
        const inviterSetting = convertJSONDataToObject(corporationDetail.inviterSetting);
        isCorporationOnEmailTicketMode = inviterSetting.emailResponseFunction;
    } else {
        isCorporationOnEmailTicketMode = corporationDetail?.limitValue?.emailResponseFunction
    }
    
    let isOnEmailTicketMode = isCorporationOnEmailTicketMode && isProjectOnEmailTicketMode;

    return isOnEmailTicketMode ? true : false;
}

const chunkArray = (myArray, chunkSize) => {
    var results = [];

    while (myArray.length) {
        results.push(myArray.splice(0, chunkSize));
    }

    return results;
}

const getEmailDomain = () => {
    let domain = process.env.REACT_APP_DOMAIN_NAME;
    let allowDomain = [
        'dbdesk-test.ominext.dev',
        'solutiondesk.jp',
        'dbdesk-staging.ominext.dev'
    ]
    let fixDomain = 'dbdesk-dev.ominext.dev';
    const cleanUrl = domain.replace(/^https?:\/\//, '').replace(/\//g, '');
    if (allowDomain.includes(cleanUrl)) {
        return cleanUrl;
    }
    return fixDomain;
}

const isDisableEditEmailTicket = (ticketDetail, projectInuseDetail, corporationDetail, statusNo) => {
    if (isEmptyObject(ticketDetail) || isEmptyObject(projectInuseDetail) || isEmptyObject(corporationDetail) || !statusNo) return false;
    if (ticketDetail.ticketType === Constant.TICKET_TYPE.EMAIL && !isEnableCreateEmailTicketMode(corporationDetail, projectInuseDetail) && statusNo === Constant.STATUS_NO.DONE) {
        return true;
    }

    return false;
}

const unEscapeHtml = (string, isKeepHtml = false) => {
    if (!string) return '';
    
    if(!isKeepHtml) string = string.replace(/(<([^>]+)>)/gi, '');
    
    return string
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&nbsp;/g, ' ')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&#039;/g, "'")
        .replace(/\\"/g, '"')
        .replace(/\\\\"/g, '"');
}

const isSystemFormChatAIPrompt = formDetail => {
    if (isEmptyObject(formDetail)) return false;
    return (
        formDetail.definition === Constant.FORM_DEFINITION.SYSTEM &&
        formDetail.type === Constant.FORM_TYPE.FORM_KNOWLEDGE &&
        formDetail.promptKnowledgeFormFlag === Constant.PROMPT_FLAG.TRUE &&
        !formDetail.definitionVariant
    );
};

const isSystemAIAutoForm = formDetail => {
    if (isEmptyObject(formDetail)) return false;

    const DEFINITION_VARIANT_AI_AUTO = [
        Constant.FORM_VARIANT.AI_AUTO.EVENT,
        Constant.FORM_VARIANT.AI_AUTO.TIMER,
        Constant.FORM_VARIANT.AI_AUTO.PERIODIC
    ];

    return (
        formDetail.definition === Constant.FORM_DEFINITION.SYSTEM &&
        DEFINITION_VARIANT_AI_AUTO.includes(formDetail?.definitionVariant)
    );
};

const isCorporationOnAI = corporationDetail => {
    let status = false;
    if (isGuestContractType(corporationDetail)) {
        if (corporationDetail?.inviterUsingChatAI === Constant.STATUS_SERVICE_PERMISSION.ON)
            status = true;
    } else {
        if (
            corporationDetail?.limitValue?.usingChatAI === Constant.STATUS_SERVICE_PERMISSION.ON &&
            corporationDetail?.limitValue?.chatAI === Constant.STATUS_SERVICE_PERMISSION.ON
        )
            status = true;
    }
    return status;
};

const isCorporationOnAIAuto = corporationDetail => {
    if (corporationDetail.contractType === Constant.CORPORATION_CONTRACT_TYPE.GUEST) {
        return false;
    }

    const isUsingAIAuto = corporationDetail.contractType === Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK
        ? convertJSONDataToObject(corporationDetail.inviterSetting)?.usingAIAuto === Constant.STATUS_SERVICE_PERMISSION.ON
        : corporationDetail?.limitValue?.usingAIAuto === Constant.STATUS_SERVICE_PERMISSION.ON;

    return isUsingAIAuto && isCorporationOnKnowledge(corporationDetail);
};


const isCorporationOnKnowledge = corporationDetail => {
    let status = false;
    if (isGuestContractType(corporationDetail)) {
        const inviterSetting = convertJSONDataToObject(corporationDetail.inviterSetting);

        if (inviterSetting?.usingKnowledgeService === Constant.ACTIVE_FLAG.ACTIVE) status = true;
    } else {
        if (corporationDetail?.knowledgeService === Constant.ACTIVE_FLAG.ACTIVE) status = true;
    }
    return status;
};

function removeDoubleQuotes(str) {
    if (str.startsWith('"') && str.endsWith('"')) {
        return str.slice(1, -1);
    }
    return str;
}

const validateDateByMinutesStep = (dateValue = new Date(), minutesStep = 1) => {
    const result = {
        status: true,
        errorMessage: ''
    };
    try {
        if(isEmptyString(dateValue)) {
            result.status = false;
            result.errorMessage = I18n.get('COMMSG014');
            return result;
        }
        // Chuyển đổi giá trị fieldValue thành Date
        const date = new Date(dateValue);
        // Kiểm tra giá trị phút
        const minutes = date.getMinutes();
        if (minutes % minutesStep !== 0) {
            result.status = false;
            result.errorMessage = I18n.get('MSG-AI-INVALID-PROMPT-EXECUTION-DATETIME');
            return result;
        }
        return result;
    } catch (error) {
        return result;
    }
};

const getUserEmailAfterDeleted = (userEmail) => {
    if (!userEmail) return '';
    return `${userEmail}${Constant.DELETED_USER_EMAIL_SUFFIX}`;
}

const getDisplayUserEmailWithoutSuffix = (mail) => {
    if (!mail) return '';

    if (mail.endsWith(Constant.DELETED_USER_EMAIL_SUFFIX)) {
        return mail.replace(Constant.DELETED_USER_EMAIL_SUFFIX, '');
    }

    return mail;
}

const Utils = {
    isCorporationOnAI,
    isCorporationOnKnowledge,
    generateRandomStr,
    convertJSONDataToObject,
    getUrlParameter,
    readFileAsArrayBuffer,
    isEmptyObject,
    isEmptyString,
    isEmptyArray,
    getUserType,
    getTimeToDateNow,
    sortByCreatedAtDesc,
    sortByCreatedAtAsc,
    sortByUpdatedAtDesc,
    sortByUpdatedAtAsc,
    sortByDeletedAtAsc,
    sortByDrillDownLabelAsc,
    sortListByLabelDictionaryASC,
    sortByHitNumberDesc,
    isValidJSONString,
    getCorporationIdFromContentId,
    getDateTimeByFormatJapanYYYY_MM_DDHHMM,
    getDateByFormatYYYY_MM_DD,
    reducerDataList,
    getGuestContractUrlParameter,
    isCreateGuestContractUrl,
    validateEmail,
    getMenuSelectedByUrl,
    isProjectManagerOfProject,
    getCloudFrontUrlForCorporationLogo,
    isEmpty,
    getValueString,
    getPrefixOfId,
    isApprovePageUrl,
    getTextByLanguage,
    isAdminUser,
    getFullNameOfUser,
    findIndexInArrayById,
    removeItemInArrayById,
    getCustomFieldValueInFormData,
    validateURL,
    isSystemField,
    isTypeObject,
    getEditTicketData,
    isImageFile,
    getDateTimeByFormatYYYYMMDDHHMM,
    getNumberOfLines,
    getPanelInfo,
    isLoginPageUrl,
    encodeDataByCrypto,
    decodeDataByCrypto,
    isAnonymousQATicket,
    isAnonymousQAWaitingTicket,
    isAnonymousQARejectTicket,
    isAnonymousQAApproveTicket,
    isDisableApproveRejectAnonymousQaTicket,
    isConfirmCreateOrgPageUrl,
    isConfirmCreateGuestOrgPageUrl,
    getUserStatus,
    getB2CStatusInProject,
    getShortName,
    formatStringUpperCaseFirstLetter,
    getUpdatedTimeTicket,
    isNotChangedName,
    detectUrl,
    replaceUrls,
    isJson,
    isExpired,
    setUpdatedTicketObject,
    normarlizeSearchKeyword,
    sortListByNameDictionaryASC,
    escapeHtmlCharacters,
    isPublicDesk,
    isPrivateDesk,
    isPublicOffDeskOff,
    isProjectGuest,
    isProjectGuestDesk,
    isGeneralUserWithoutGuest,
    isGeneralUser,
    isB2CUser,
    isGuestUser,
    isGuestContractType,
    findObjectById,
    sortListByNameCorpDictionaryASC,
    getPanelData,
    filterDuplicateItemsOfArray,
    checkArrayIncludesItem,
    removeDuplicateInArrayById,
    upperCaseFirstLetter,
    lowerCaseAllWordsExceptFirstLetters,
    compareTwoArraySorted,
    getUserNameWithActiveStatus,
    getCorporationName,
    getCorporationNameSuffixText,
    sortFullNameUserByDictionaryASC,
    getFullNameOfUserWithCorporationStatus,
    getFileExtension,
    convertByteToGigaByte,
    updateNewDisplayNameOfProject,
    getTypeUser,
    handleRequest,
    getTitle,
    getDateTimeByFormatHHMM,
    sortListByTitle,
    getCloudFrontUrlOfFilePublicAll,
    sortListTagFieldOfCategory,
    sortCategoriesByOrder,
    generateChatBotScript,
    isAnonymousQATicketFromLiveChat,
    isAnonymousQALiveChatTicket,
    isAnonymousQAEndLiveChatTicket,
    isAnonymousQAWaitingOrEndLiveChatTicket,
    isAnonymousLiveChatWaitingTicket,
    isAnonymousQAWaitingTicketFromLiveChat,
    initKeyFileSaveLogCreateTicketByUploadCsv,
    renderUploadKeys,
    getPaginatedItems,
    mappingLogStatusText,
    mappingLogMessageByStatus,
    mappingLogErrorList,
    genKeyFileCss,
    randomSubDomainCorporation,
    getUrlLinkCorporation,
    getUrlLinkCorporationSignedIn,
    getChatPermissionOfTicket,
    getUrlLinkShareLinkTicket,
    getCurrentUrlCaseLogin,
    removeExcessZeros,
    convertDecimalNumber,
    sortListByOrderDesc,
    convertNumber,
    isNumber,
    areArraysEqual,
    getViewPermissionLabel,
    checkNumberDecimal,
    isDisabledTicketExternalWhenDeskServiceOff,
    checkUserPermission,
    getProjectName,
    sortDrillDownByNo,
    arraysHaveSameElements,
    isDisplaySplitPdfSwitch,
    isNumeric,
    convertLatinNumberToJapaneseFullWidthNumber,
    removeDigitsFromStringsArray,
    removeLatinAndJapaneseFullwidthNumbers,
    isJapaneseFullwidthNumber,
    convertJapaneseFullWidthNumberToLatinNumber,
    isNameOrUrlOfPdfFile,
    isUsingFunctionService,
    setDefaultPermissionForSynonymForm,
    validateSizeOfFieldTicket,
    isValidLinkPromptInput,
    getTicketIdFromSharedLink,
    destructLinkPrompt,
    createTagForLogKibana,
    mappingLogSuccessList,
    uploadFileWithRetry,
    validateStyleSheet,
    getShareLinkUrls,
    convertByteToMB,
    splitArrayIntoSubArrays,
    sortByExecuteTimeAtDesc,
    getUniqueObjectListInArray,
    filterDuplicates,
    initKeyFileSaveLogBulkAction,
    isAnonymousQALiveChatDeleted,
    isAnonymousQATicketFromChatBotAndFinish,
    isAnonymousQATicketFromIframe,
    isStringOverLength,
    getCurrentDevice,
    delay,
    sortListBookmarkByNameASC,
    getArrByBreakline,
    validIpAddressFormat,
    isNumberExludeStartZero,
    findDuplicateData,
    detectUrlType,
    removeExtraCharactersOfIdTicket,
    isEnableCreateEmailTicketMode,
    chunkArray,
    getEmailDomain,
    giveBackHtmlCharacters,
    isEnableCreateEmailTicketModeOnSkipProjectType,
    validateEmailVer2,
    isDisableEditEmailTicket,
    unEscapeHtml,
    isSystemFormChatAIPrompt,
    removeDoubleQuotes,
    isSystemAIAutoForm,
    isCorporationOnAIAuto,
    validateDateByMinutesStep,
    getUserEmailAfterDeleted,
    getDisplayUserEmailWithoutSuffix,
};

export default Utils;
