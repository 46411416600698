// prettier-ignore
const jp = {
    'Sign In': '',
    'Sign Up': '',
    'global.field.empty': 'This field cannot be blank',
    'global.field.invalid': '入力したデータのフォーマットが正しくありません。',
    'MSG_DELETED': 'このメッセージは、作成者により削除されました。',
    'create.organization.title': '組織登録申し込み',
    'create.organization.btn.submit': '申し込む',
    'create.organization.msg.required.mailAddress': 'This field cannot be blank',
    'logScreen.list.title': 'ログ一覧',
    'logScreen.detail.title': 'ログ詳細',
    'logScreen.detail.fileName': 'ファイル名',
    'logScreen.detail.bulkActionName': '操作',
    'logScreen.detail.buttonBack': '戻る',
    'MSG-B2B-SET-NEW-COUNTER-SUCCESS': '窓口開設されました。',

    'MSG-CM-CREATE-TICKET-SUCCESS': 'コンテンツを保存しました。', //'チケットが作成されました。',
    'MSG-CM-SAVE-DRAFT-TICKET-SUCCESS': 'コンテンツを下書き保存しました。', // 'チケットが一時保存されました。',
    'MSG-CM-PUBLISH-DRAFT-CONTENT-SUCCESSFULLY': 'コンテンツを公開しました。',
    'MSG-CM-SAVE-CONTENT-SUCCESSFULLY': 'コンテンツを保存しました。',

    'MSG-CM-UPDATE-DATA-SUCCESS': '変更内容が保存されました。',
    'MSG-CM-NO-DATA-UPDATED': '更新内容が見つかりません。',
    'MSG-CM-DELETED-CONTENT':'本コンテンツは存在しません。',

    'COMM01LB001': '代表者メールアドレス',
    'COMM01LB002': '代表者姓',
    'COMM01LB003': '代表者名',
    'COMM01LB004': '電話番号',
    'COMM01LB005': '組織名',
    'COMM01LB006': '部門名',
    'COMM01LB007': 'ご利用の組織ID',
    'COMM01LB008': '代表者パスワード',

    'COMM01MSG01': '代表者メールアドレスを入力してください。',
    'COMM01MSG03': 'サブドメインを入力してください。',
    'COMM01MSG04': 'ご記入のサブドメインは既に登録されています。',
    'COMM01MSG05': 'パスワードを入力してください。',
    'COMM01MSG06': '申し込みが完了しました。\nメールをご確認下さい。',
    'COMM01MSG07': 'メール形式が正しくありません。',
    'COMM01MSG08': '入力したデータのフォーマットが正しくありません。',
    'COMM01MSG11': 'このフィールドは入力必須です。',
    'COMM01MSG15': '入力されたサブドメインは現在登録承認待ちです。\nメールを確認して登録手続きを完了してください。',
    'COMM01MSG16': '確認しようとしているサブドメインは既に登録されています。\n「はい」ボタンをクリックして別のサブドメインで再度登録してください。',
    'COMM01MSG17': '登録リンクの有効期限が切れになりました。',
    'COMMSG043': 'タブを閉じますと、入力したデータが保存されません。宜しいでしょうか。',
    'COMMSG044': 'パネルを閉じますと、タブに入力したデータが保存されません。宜しいでしょうか。',
    'COMMSG047': '変更内容が保存されました。',
    'COMMSG049': 'このユーザーを再度追加して宜しいでしょうか？',
    'COMMSG050': '一部の機能が無効となります。管理者モードをOFFにして宜しいでしょうか？',
    'COMMSG060': '選択肢がありません。',
    'COMMSG059': 'チケットを削除すると、チケットの編集やチャットができなくなります。よろしいですか？',
    'COMMSG057': 'コンテンツを下書き保存しました。', // 'チケットが一時保存されました。',
    'COMMSG067': '{{item}} を入力してください',
    
    // Common 02 - Confirm Register Org
    'COMM02MSG06': '組織登録の申し込みはキャンセルしてよろしいでしょうか。',
    'COMM02MSG07': '組織登録が既に承認されました。',
    'COMM02MSG08': '組織登録が既にキャンセルされました。',
    'COMM02MSG09': 'サブドメインは既に登録されています。\n「はい」ボタンをクリックして別のサブドメインで再度登録してください。',
    'COMM02MSG11': 'プロジェクト作成依頼が承認されました。',
    'COMM02MSG12': 'プロジェクト作成依頼が却下されました。',

    // Common msg
    'COMMSG004': '変更を保存しますか？',
    'COMMSG013': '入力したアドレスは既に登録されています。',
    'COMMSG015': 'URLを入力してください。',
    'COMMSG016': 'フォーマットを削除して宜しいでしょうか？',
    'COMMSG017': 'ステータスを削除して宜しいでしょうか？',
    
    'COMMSG022': '{{maxLength}}文字以下で入力してください。',
    'COMMSG023': '選択項目を入力してください。',
    'COMMSG024': 'ステータを入力してください。',
    'COMMSG025': 'フィールドを削除して宜しいでしょうか？',
    'COMMSG026': '{{minLength}}文字以上で入力してください。',
    'COMMSG027': '{{maxLength}}文字以下で入力してください。',
    'COMMSG028': '入力したデータが破棄されます。宜しいでしょうか？',
    'COMMSG029': 'ビューを削除して宜しいでしょうか？',
    'COMMSG032': 'ビューを非表示して宜しいでしょうか？',
    'COMMSG036': '確認用メールアドレスが一致しません。',
    'COMMSG041': 'コンテンツを保存しました。', // 'チケットが作成されました。',
    'COMMSG042': 'チケットが保存されました。',
    'COMMSG045': '変更内容が保存されました。',
    'COMMSG058': 'このフィールドは入力必須です。最小限は一つの選択肢を設定してください。',
    'COMMSG038': 'このチケットを削除して宜しいでしょうか？',
    'COMMSG053': 'チケットを削除すると、チケットの編集やチャットができなくなります。よろしいですか？',

    //#region System_Msg
    'SYSMSG001': 'エラーが発生しました。\n管理者に連絡してください',
    'SYSMSG002': 'インターネットの接続を確認してください。',
    'SYSMSG003': 'タイムアウトが発生しました。',
    //#endregion

    'USER01MSG005': '選択したユーザーの権限は変更できません。担当者にご連絡ください。',
    'USER01MSG006': '選択したユーザーは削除できません。 担当者にご連絡ください。',
    'USER01MSG007': '画像ファイルの形式は正しくありません。',
    'USER01MSG008': 'このアカウントは管理者により削除されました。担当者にご連絡ください。',
    'USER01MSG004': 'ユーザーのIDを入力してください。',
    'USER09MSG007': 'ユーザーIDが重複しています。',

    'COMBTN001': 'キャンセル',
    'COMBTN002': 'はい',
    'COMBTN003': '適用',
    'COMBTN004': 'OK',
    'COMBTN006': '閉じる',
    'COMBTN007': 'いいえ',
    'COMBTN009': 'もっと見る',
    'COMBTN010': '隠す',
    'COMBTN011': '保存',
    'COMBTN012': '編集',
    'COMBTN013': '削除',
    'COMBTN014': '復活',
    'COMBTN015': '絵文字追加',
    'COMBTN016': '編集履歴',
    'CBOTMSG016': 'エージェントと接続しています。',
    'CBOTBT005': 'すぐにチャット',
    'OK': 'OK',

    'USER14P001': '数値を入力してください',
    'USER14MSG010': '{{current_genneral_number}} 名のジェネラルユーザーが使用されていますので、設定が変更できません。',
    'USER14MSG017': '{{current_ticket_number}}件のチケットが発行されていますので、設定が変更できません。',
    'USER14MSG018': '{{current_storage}}Gbのストレッジ容量が使用されていますので、設定が変更できません。',
    'USER01MSG001': '入力したIDは既に登録されています。 担当者にご連絡ください。',
    'USER02MSG001': '画像ファイルの形式は正しくありません。',
    'USER03MSG002': 'プロジェクト名を入力してください。',
    'USER03MSG001': '入力したプロジェクト名は既に登録されています。他の名称を入力してください。',
    'USER01MSG009': '選択したユーザーは現在のプロジェクトにて変更できません。担当者にご連絡ください。',
    'USER01MSG010': 'このアカウントはプロジェクトから外されました。担当者にご連絡ください。',
    'USER04MSG001': 'ヘッドラインを入力してください。',
    'USER04MSG002': '問い合わせ先を選択してください。',
    'USER04MSG003': '問い合わせ先デスクを選択してください。',
    'USER04MSG010': '代理投稿のカスタマーを選択してください。',
    'USER04MSG030': 'このプロジェクトは既に登録されています。',
    'USER14LB010': 'サービスデスク機能',
    'USER14LB011': 'ナレッジ機能',
    'USER14LB012': '課題管理機能',
    'USER14LB013': '公開サービス機能',
    'COMMSG001': '一致する結果は見つかりませんでした。',
    'USER07MSG001': '日時の形式は正しくありません。',
    'USER07MSG002': 'チケット発行日時から現在時刻までの間を選択してください。',
    'USER07MSG003': '日時を入力してください。',
    'USER07MSG004': 'ファイル送信に失敗しました。',
    'USER14MSG004': 'この組織はサービスデスク機能が利用できなくなります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG005': 'この組織はサービスデスク機能が利用できるようになります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG006': 'この組織はナレッジ機能が利用できなくなります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG007': 'この組織はナレッジ機能が利用できるようになります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG008': 'この組織は課題管理機能が利用できなくなります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG009': 'この組織は課題管理機能が利用できるようになります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG010-FAKE': 'この組織はAI連携機能が利用できるようになります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG011-FAKE': 'この組織はAI連携機能が利用できなくなります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG011': '現契約で設定されるジェネラルユーザーの上限を超えています。追加オプション購入など上限拡大をお願いします。',
    'USER14MSG014': '現契約で設定される上限を超えている項目があります。追加オプション購入など上限拡大をお願いします。',
    'USER14MSG025': 'この組織は公開サービス機能が利用できるようになります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG024': 'この組織は公開サービス機能が利用できなくなります。引き続き設定変更して宜しいでしょうか？',
    'USER14MSG026': 'この組織のRoot権限を有効にしてよろしいでしょうか？',
    'USER14MSG027': 'この組織のRoot権限を無効にしてよろしいでしょうか？',
    'PROJECT_SELECTED_NULL': '問い合わせ先デスクを選択してください。',
    'USER04MSG004': 'アップロードに失敗しました',
    'CONTENT_MSG_NULL': 'メッセージが削除されました。',
    'USER04MSG007': '推薦可能デスク数の上限に達しました。Accelaまで問い合わせください。',
    'USER04MSG006': '初期Adminアドレスを入力してください。',
    'COMM01MSG09': '登録されたサブドメインにプロジェクト作成依頼権限がありません。',
    'COMMSG002': '受信エラーが発生しました。 接続を再度試みてください。',
    'COMBTN005': '再接続',
    'COMLB001': '接続エラー',
    'COMM01MSG10': '推薦されたプロジェクトが既に登録されています。',
    'COMMSG005': 'フォーマットを選択してください。',
    'USER04MSG016': '問い合わせ先を変更するとフィールドの入力値がリセットされます。よろしいですか？',
    'USER04MSG017': 'フォーマットを変更するとフィールドの入力値がリセットされます。よろしいですか？',
    'USER12MSG005': 'このユーザーを削除して宜しいでしょうか？',
    'USER03BTN006': 'プロジェクトメンバー管理',
    'USER03BTN007': '個人カスタマー管理',
    'USER03BTN008': '企業カスタマー管理',
    'USER03LB014': '組織ロゴ',
    'USER03MSG004': 'このプロジェクトが削除することができません。',
    'USER03MSG003': 'このプロジェクトを削除して宜しいでしょうか？',
    'USER03MSG006': '新規プロジェクトの追加が完了しました。',
    'USER11MSG004': 'このアドレスは既にSolutionDeskに登録されているため、使用できません。',
    // This message has hit the limit of 20 reactions.
    'USER07MSG006': 'このメッセージは 20 個のリアクションの制限に達しました。',
    // You can't add more than 10 reaction to a message
    'USER07MSG007': '1つのメッセージに10個以上のリアクションが追加できません。',

    'ERROR_PROJECT_SELECTED': '連絡先を選択してください。',
    'ERROR_ALL_GUEST_PROJECT_EMPTY': '現在、システム上にゲストプロジェクトが一個も存在していません。',

    // Common Msg
    'COMMSG030': 'データが変更されました。ページを更新してください。',

    'COMMSG039': 'チケットが削除されました。',
    'MSG-CM-DELETE-CONTENT-SUCCESSFULLY': 'コンテンツを削除しました。',
    'MSG-CM-COMPLETE-DELETE-CONTENT-SUCCESSFULLY': 'コンテンツを完全削除しました。',

    'COMMSG040': '入力したメールアドレスは既に登録されています。',

    // status field
    'STAP001': 'ステータス名',
    'STAMSG001': 'ステータスを入力してください。',
    'STAMSG002': 'ステータスを削除して宜しいでしょうか？',

    // tag field
    'TAGP001': '項目名',
    'TAGMSG001': '選択項目を入力してください。',
    'TAGMSG002': '選択項目を削除して宜しいでしょうか？',

    // Tag item
    'TAG_TITLE': '選択',
    // Field master system master
    'TAGTTL001': '以下の選択項目は使用しない',
    'TAGBTN001': 'カスタマイズ',
    'TAGBTN002': 'マスターナレッジ',
    'TAGBTN003': 'AutoFill',
    'TAGBTN004': 'システムマスター',
    'TAGBTN005': '全組織',
    'TAGBTN006': '自組織',
    'TAGBTN007': 'カスタマー組織',
    'TAGBTN008': 'サポート組織',
    'TAGBTN009': '全プロジェクト',
    'TAGBTN010': '自プロジェクト',
    'TAGBTN011': 'ベースプロジェクト',
    'TAGBTN012': 'B2Cカスタマー',
    'TAGBTN013': 'ジェネラルプロジェクト',
    'TAGBTN014': 'デスクON',
    'TAGBTN015': 'デスクON&公開',
    'TAGBTN016': 'ゲストプロジェクト',
    'TAGBTN017': 'ゲストデスクプロジェクト',
    'TAGBTN018': '全ユーザー',
    'TAGBTN019': 'ジェネラル',
    'TAGBTN020': 'Admin',
    'TAGBTN021': 'プロジェクトマネージャー',
    'TAGBTN022': 'エージェント',
    'TAGBTN023': 'Internalカスタマー',
    'TAGBTN024': 'B2Cカスタマー',
    'TAGBTN025': 'ゲスト',
    'TAGBTN026': '新しいマスターナレッジを選択項目に自動追加する',
    'TAGBTN027': 'フィールドを連動する',
    'TAGBTN028': 'プロンプトナレッジ',
    'TAGBTN029': '類義語ナレッジ',

    'TAGLB001': '項目',
    'TAGLB002': '使用するフィールド',
    'TAGLB003': '組織',
    'TAGLB004': 'プロジェクト',
    'TAGLB005': 'ユーザー',

    // DillDown and search option
    'DRILBTN001': '検索オプションを開く',
    'DRILBTN002': '検索オプションを閉じる',

    'DRILLB001': '最近',
    'DRILLB002': '日間',
    'DRILLB003': '週間',
    'DRILLB004': '日付範囲（投稿日）',
    'DRILLB005': 'キーワード条件',
    'DRILLB006': 'コンテンツ条件',
    'DRILLB007': 'タグ条件',
    'DRILLB008': '表示オプション',
    'DRILLB009': 'ソート順',
    'DRILLB010': '全て',
    'DRILLB011': 'いずれか',
    'DRILLB012': '本文',
    'DRILLB013': 'チャット',
    'AUTH01MSG10': '入力したサブドメインは存在しておりません。',
    'LABEL-OPTION-FILE_PAGE_KNOWLEDGE': '添付ファイルオプション',
    'LABEL-INCLUDE_PDF_FILE': 'PDFを含める',
    'LABEL-INCLUDE_PDF_PAGE': 'PDFページを含める',
    'LABEL_INCLUDE_ATTACH_PDF': '添付PDF含む',

    'COMMSG014': 'このフィールドは入力必須です。',
    'COMMSG034': '選択項目がありません。',
    'FIELDFORMLB004': 'カスタマー操作',
    'MSG-CM-WRONG-NUMBER-FORMAT': '半角英数字のみ入力してください。また値は{{min_value}}以上に設定してください。',
    'MSG-CM-WRONG-NUMBER-DOT-FORMAT': '半角英数字と「. 」のみ入力してください。また値は0より大きく設定してください。',
    'MSG-CM-STORAGE-ERROR': '整数部分は4文字以下、少数部分は3文字以下に入力してください。また値は0より大きく設定してください。',

    // Panel title
    'KNOWLITT001': 'ナレッジ一覧',
    'KNOWLITT002': '現在プロジェクトナレッジ一覧',
    'KNOWLITT003': '問い合わせ先ナレッジ一覧',
    'KNOWLILB005': '公開ナレッジ',

    'DRILTT002': '現在プロジェクトドリルダウン＆詳細検索',
    'CM-PANEL-KNOWLEDGE-DRILLDOWN': 'ナレッジドリルダウン＆詳細検索',
    'DRILTT003': '問い合わせ先ドリルダウン＆詳細検索',
    'CM-BUTTON-OPEN-DRILLDOWN': '詳細',

    'KNOWDETT001': 'ナレッジ詳細',
    'KNOWDETT002': '現在プロジェクトナレッジ詳細',
    'CM-PANEL-KNOWLEDGE-DETAIL': 'ナレッジ詳細',
    'KNOWDETT003': '問い合わせ先ナレッジ詳細',

    'MANAGEMENT_TITLE':'サポート窓口管理',
    'PUBLIC_KNOWLEDGE_SETTING_TITLE': '公開ナレッジ管理',
    // User 03 - Project
    'USER03BTN001': '作成',
    'USER03BTN002': 'プロジェクト管理',
    'USER03BTN003': 'プロジェクトメンバー管理',
    'USER03BTN004': '新規プロジェクト追加',
    'USER03BTN005': '新規メンバー追加',

    'USER03LB001': 'プロジェクト名',
    'USER03LB011': 'メンバー',
    'USER03LB012': 'プロジェクトマネージャー',

    'USER03TT005': 'ナレッジロボ管理',

    // USER 04 Create/Edit/Detail ticket
    'USER04BTN001': '送信',
    'USER04BTN007': '組織内プロジェクト',
    'USER04BTN008': '組織外プロジェクト',
    'USER04BTN009': '問い合わせ窓口追加',
    'USER04BTN010': '既存サービスデスクプロジェクトを選ぶ',
    'USER04BTN011': 'パートナープロジェクト作成',
    'USER04LB054' : '担当者',

    'USER04BTN022': '保存しない',
    'USER04BTN023': '送信しない',
    'USER04BTN031': 'カスタマープロジェクト',
    'USER04BTN038': 'サポート対象プロジェクト作成',
    'USER04BTN039': 'ゲストカスタマー登録',
    'USER04BTN040': 'プライベート',
    'USER04BTN041': 'プロジェクトメンバー',
    'USER04BTN054': '承認',
    'USER04BTN055': '却下',

    'USER04LB001': '問い合わせ概要',
    'USER04LB002': '問い合わせ先デスク',
    'USER04LB003': '詳細',
    'USER04LB004': 'コンテンツID',
    'USER04LB005': 'チケットタイプ',
    'USER04LB006': '概要',
    'USER04LB007': '投稿日',
    'USER04LB008': '更新日',
    'USER04LB009': 'メモ',
    'USER04LB010': '添付',
    'USER04LB011': '添付データー種類',
    'USER04LB012': 'ステータス',
    'USER04LB013': 'エスカレーション元のカスタマー',
    // 'USER04LB014': 'エスカレーション先',
    // 'USER04LB015': 'エスカレーション元のカスタマー',
    'USER04LB016': 'エスカレーション先',
    'USER04LB017': 'アンケート（コメント）',
    'USER04LB018': '足跡',
    'USER04LB019': '問い合わせ元',
    'USER04LB020': '問い合わせ先',
    'USER04LB021': '発行者',
    'USER04LB022': '問い合わせ詳細',
    'USER04LB023': '問い合わせカテゴリー',
    'USER04LB024': '添付ファイル',
    'USER04LB025': 'カテゴリー',
    'USER04LB026': '受付',
    'USER04LB027': 'チケット状態',
    'USER04LB028': 'フォーマット',
    'USER04LB029': '問い合わせ先',

    'USER04LB052': '未登録カスタマー',
    

    'USER04MSG020': '添付ファイルが送信されました。',
    'USER04MSG021': '画像が送信されました。',
    'USER04MSG022': 'チケット種類を変更するとフィールドの入力値がリセットされます。よろしいですか？',

    'USER04MSG018': 'チケットの変更内容を保存しますか？',
    'MSG-CM-CONFIRM-CANCEL-DATA-CHANGE': 'コンテンツの変更内容を破棄しますか？',

    'USER04MSG019': 'チケットの変更内容を送信しますか？',
    'USER04MSG024': '本チケットは他のユーザーにより更新されました。引き続き更新しますでしょうか。',
    'USER04MSG031': '入力情報で推薦チケットを発行済です。追加で発行してよろしいでしょうか？',

    'USER04P001': 'サポート取引よろしくお願いします。',
    'USER04P002': '取引申請',
    'USER04P003': '弊社サポート窓口を開設しました。',
    'USER04P004': 'サポート対象プロジェクト作成',
    'USER04P005': '"登録ありがとうございます。本チケットに対してチャットで問い合わせ可能です。また、新規の問い合わせ案件ごとに、「新規問い合わせ」ボタンより新たなチケットを発行し、問い合わせを行うなど各種サポートを受けることができます。"',
    'USER04P006': 'ゲストカスタマー登録',
    'USER04P007': 'パートナープロジェクト作成',
    'USER04P008': '"ヘルプデスクの開設ありがとうございます。本チケットに対してチャットで返信可能です。"',
    'USER04P009': '検索キーワード',
    
    'USER04MSG025': 'チケットを送信完了しました。',
    'USER04MSG026': '本チケットを承認して宜しいでしょうか？',
    'USER04MSG027': '本チケットを却下して宜しいでしょうか？',
    'USER04MSG028': 'チケットが承認されました。',
    'USER04MSG029': 'チケットが却下されました。',
    'CM-LABEL-FORMAT-ID': 'フォーマットID',
    'CM-LABEL-FIELD-ID': 'フィールドID',
    'MSG-CM-BACK-TO-NEW-SUPPORT-CONTENT-SUCCESSFULLY': 'コンテンツをアクティブにしました。',

    // User 08 - Helpdesk drilldown and search option
    'USER08TT001': 'チケットドリルダウン&詳細検索',

    'USER08LB001': '問い合わせ発行元プロジェクト',
    'USER08LB002': 'お知らせ発行先プロジェクト',
    'USER08LB003': 'お知らせ発行先ユーザー',
    'USER04BTN034': '組織内ユーザー',
    'USER04BTN033': 'ゲストカスタマーユーザー',
    'AUTH01BTN001': 'ログイン',
    'AUTH01LB001': 'ユーザーID',
    'AUTH01LB002': 'パスワード',

    'AUTH01LB003': 'ログイン情報を保存する。',
    'AUTH01LB004': 'パスワードをお忘れですか？',
    'AUTH01LB005': 'ログインID登録の無いお客様は',
    'AUTH01LB006': 'こちら',
    'AUTH01LB007': 'からお問合わせください。',
    'AUTH01BTN002': '確認',
    'AUTH01LB008': 'ログイン情報をご入力ください。',
    'AUTH01LB009': '確認コード',
    'AUTH01LB011': '新規パスワード',
    'AUTH01BTN004': '確認',
    'AUTH01P002': '新規パスワードを入力してください',

    'COMM01TT001': '組織の新規登録',
    'COMM01LB009': '契約タイプ　トライアル',
    'COMM01LB010': 'トライアル',
    'COMM01LB011': 'スタンダード',
    'COMM01LB012': 'プレミアム',
    'COMM01LB014': '代表者の情報',
    'COMM01LB013': '組織の情報',
    'COMM01P004': '代表者名を入力してください',
    'COMM01P003': '代表者姓を入力してください',
    'COMM01P001': '組織名を入力してください',
    'COMM01P002': '部門名を入力してください',
    'AUTH01P001': '確認コードを入力してください',
    'TICKET_TYPE_DRILLDOWN': 'チケット種類',
    'EMAIL_TICKET_ORIGIN_DRILLDOWN_TEXT': '発行元',
    'EMAIL_TICKET_SDESK_SEND_DRILLDOWN_TEXT': 'プロジェクト',
    'EMAIL_TICKET_SDESK_RECEIVE_DRILLDOWN_TEXT': 'カスタマー',

    // User 09 - Org user list
    'USER09BTN001': '新規ユーザー追加',
    'AUTH01LB010':'ログインID登録の無いお客様はこちらからお問合わせください。',
    'AUTH01MSG13':'ログインが完了しました。下のボタンをクリックして、サービスをご利用になれます。',
    'AUTH01MSG16': '許可拒否！このリンクにアクセスするには、管理者にご連絡ください',

    'USER09MSG001': '入力値がリセットされます。よろしいでしょうか？',
    'USER09MSG002': '入力したユーザーIDは既に登録されています。',
    'USER09MSG006': 'このユーザーを削除して宜しいでしょうか？',
    'USER09MSG008': 'このユーザーが削除することができません。',
    'USER09MSG009': '最低1つのアドミン権限を持つメンバーを残してください。',
    'USER09MSG010': 'このカスタマーを削除して宜しいでしょうか？',
    'MSG-DELETE-REPRESENT-USER': 'このユーザーは代表者に設定されているため、削除できません。',
    'MSG-DELETE-LAST-PM': 'このユーザーは[プロジェクト名]プロジェクトの唯一のプロジェクトマネージャーであるため、削除できません。',
    'MSG-CHANGE-AUTHORITY-REPRESENT-USER': 'このユーザーは代表者に設定されているため、権限を変更できません。',

    // User 09 - user project list
    'USER09MSG003': 'ユーザーIDの追加が完了しました。',

    // user 10 - setting iframe
    'USER10BTN001': 'カラーを選択',
    'USER10BTN002': 'リセット',
    'USER10BTN003': 'コードを作成',
    'USER10BTN004': 'コードをコピー',

    'USER010LB001': '埋め込むiframeのスタイルを構成',
    'USER010LB002': 'フレームの幅',
    'USER010LB003': 'フレームの高さ',
    'USER010LB004': 'マージンの幅',
    'USER010LB005': 'マージンの高さ',
    'USER010LB006': 'ボーダー',
    'USER010LB007': 'ボーダーのスタイル',
    'USER010LB008': 'None',
    'USER010LB009': 'Solid',
    'USER010LB010': 'Dashed',
    'USER010LB011': 'ボーダーのサイズ',
    'USER010LB012': 'ボーダーのカラー',
    'USER010LB013': 'バックグラウンドのカラー',
    'USER010LB014': 'アクセス公開範囲を設定',
    'USER010LB015': 'ウェブURL公開',
    'USER010LB016': '登録済ユーザーアクセス',
    'USER010LB017': '未登録ユーザーアクセス',
    'USER010LB018': '埋め込むコード',
    'AUTH01MSG03': 'ユーザーIDまたはパスワードが異なります。',
    'AUTH01MSG12': 'ログインURLにサブドメインを入力してください。',
    'CUSTOMER-INVITATION-SETTING': '個人カスタマー招待設定',

    'USER010LB019': 'スタイルシート読み込み', //Style sheet
    'USER010LB020': 'ヘッダ', //header
    'USER010LB021': 'フッタ', //footer,
    'USER010PL001': 'スタイルシートを入力してください',
    'USER010PL002': 'ヘッダを入力してください',
    'USER010PL003': 'フッタを入力してください',
    'USER010LB022': '埋め込むiframeのコンテンツを設定',
    'COMMSG037': '最大文字数制限（＜X＞文字）を超えています。',
    'USER07BTN005': 'カスタマー参考',
    'USER07BTN006': 'カスタマー対応',
    'USER07BTN007': 'プロジェクト内',
    // User 12 - B2C + B2B customer
    'USER12MSG001': 'リンクの有効期限が切れています。管理者に連絡してください。',
    'USER12MSG006': 'ユーザーIDの追加が完了しました。',
    'USER12MSG009': '情報が更新されました。',
    'USER12MSG010': '修正内容が保存されません。宜しいでしょうか。',
    'FIELDFORMLB041': '以下のフォーマットは使用する',
    'FIELDFORMLB009': '以下のフォーマットは使用しない',
    'FIELDFORMMSG002': '新規フォーマットまたフィールドの追加が完了しました。',
    'FIELDFORMBTN007': '問い合わせ',
    'FIELDFORMBTN008': 'お知らせ',
    'FIELDFORMBTN009': 'タスク',
    'FIELDFORMBTN010': 'ナレッジ',
    'FIELDFORMBTN011': 'マスター',
    'FIELDFORMBTN023':'種類',
    'FIELDFORMBTN012':'システムフォーマット',
    'FIELDFORMLB007': '定義',
    'FIELDFORMBTN017':'システム',
    'FIELDFORMLB008':'利用状況',
    'FIELDFORMBTN015': '使用中',
    'FIELDFORMBTN016':'不使用',
    'USER01BTN007':'編集',
    'FIELDFORMBTN013':'複製',
    'USER01LB006': '削除',
    'FIELDFORMTT002':'フォーマット一覧',
    'FIELDFORMLB022': 'ステータス',
    'AUTH01MSG22': 'このメールアドレスは登録されておりません。',
    'AUTH01MSG20':'ご登録メールアドレスを入力してください。',
    'AUTH01MSG21': 'メールアドレスが正しくありません。',
    'AUTH01MSG23':'パスワードのフォーマットが正しくありません。',
    'AUTH01MSG24': '確認用パスワードが一致していません。',
    'AUTH01MSG18': '新規パスワードを入力してください。',
    'AUTH01MSG19': ' 新規パスワード処理の3分有効期限が切れになりました。ログイン画面へ戻り、仮パスワードを入力してください。',
    'USER12MSG008': '{姓/名}を入力してください。',
    'USER12MSG011': '進行中の問い合わせがあるため、削除することができません。',
    'COMMSG048': '入力したデータが破棄されます。宜しいでしょうか？',
    'FIELDFORMLB044': '組織内プロジェクト',
    'FIELDFORMLB045': '他の組織',
    'COMMSG064': 'このチケットを復元して宜しいでしょうか？',
    'COMMSG061': 'チケットが復元されました。',
    'COMMSG063': '完全削除すると、復元できません。引き続き完全削除して宜しいでしょうか？',
    'COMMSG068': '本チケットはシステム上から削除されました。',
    'COMMSG069': '削除済み',
    'COMMSG012': '{{Y}} 以下の値で入力してください。',
    'AUTH01MSG09':'エラーが発生しました。 管理者に連絡してください。',
    'USER14MSG002':'この組織を運用停止すると、所属しているユーザーはログインできなくなります。引き続き運用停止して宜しいでしょうか？',
    'USER14MSG003':'この組織を運用停止解除してよろしいでしょうか？',
    'KNOWDEMSG001': 'このチケットを公開状態から取り戻して宜しいでしょうか？',
    'MSG-CM-CONFIRM-BACK-TO-DRAFT': 'このコンテンツを下書きに戻してよろしいですか？',
    'USER14MSG001':'この組織を削除して宜しいでしょうか？',
    'USER14MSG001-1': 'この組織は運用中若しくは共有したフォーマット/フィールドが利用されていますため、削除する事できません。',
    'USER14MSG015': '発行チケット数の上限を超えています。',
    'USER14MSG016': 'ストレッジ容量の上限を超えています。',
    'USER14MSG022': 'ジェネラルユーザー数の上限を超えています。引き続きゲストデスク依頼チケットを発行して宜しいでしょうか？',
    'COMMSG021': '{{fileSize}}MB以下のサイズでファイルをアップロードしてください。',
    'PLKNOWMSG003': '入力したURLリンクは既に登録されています。他のURLリンクを入力してください。',
    'PLKNOWMSG002': '現在ナレッジサイトは使えません。',
    'PLKNOWMSG005': '公開サイトのURLが無効となります。また、編集内容は保存されません。よろしいですか？',
    'PLKNOWMSG006': '※その他の選択肢は下の入力ボックスから選んでください。',
    'PLKNOWTT001': '公開ナレッジサイトの詳細設定',
    'PLKNOWTT002': '埋め込むコンテンツを設定',
    'PLKNOWTT003': '公開サイトのURL',
    'PLKNOWP001': '自由メッセージを入力ください。',
    'PLKNOWP002':'最大値を入力ください。',
    'PLKNOWP003': '表示文面を入力ください。',
    'PLKNOWP004': 'ナレッジロボ名を入力ください。',
    'PLKNOWP005': '絞り込み完了とするナレッジのヒット件数を入力してください。',
    'PLKNOWP006': 'スクリプトを入力してください。',
    'PLKNOWLB001': 'URLを有効化する',
    'PLKNOWLB002': 'ナレッジ一覧を表示する',
    'PLKNOWLB003': 'ナレッジロボを表示する',
    'PLKNOWLB004': '公開ウェブフォームを表示する',
    'PLKNOWLB005': 'スタイルシート読み込み',
    'PLKNOWLB006': 'ヘッダ',
    'PLKNOWLB007': 'フッタ',
    'PLKNOWLB008': 'サイトロゴ',
    'PLKNOWLB009': '画像ファイルを選択',
    'PLKNOWLB010': 'URLを編集',
    'PLKNOWLB011': 'URLをコピー',
    'PLKNOWLB012': 'ナレッジロボ機能',
    'PLKNOWLB013': '選択肢に画像を使う',
    'PLKNOWLB014': '回答用フォーマット設定',
    'PLKNOWLB015': '一緒に絞り込まれる検索条件の設定',
    'PLKNOWLB016': '解決しなかった場合の動作',
    'PLKNOWLB017': '自由メッセージの内容設定',
    'PLKNOWLB018': '選択肢の最大表示設定',
    'PLKNOWLB019': 'ヘッダに表示するテキスト',
    'PLKNOWLB020': 'ヘッダの色',
    'PLKNOWLB021': 'ナレッジロボ名',
    'PLKNOWLB022': 'ナレッジロボのアイコン',
    'PLKNOWLB023': 'ナレッジロボ設定',
    'PLKNOWLB024': 'ナレッジロボの詳細設定',
    'PLKNOWLB028': '絞り込み完了とするナレッジのヒット件数',
    'PLKNOWLB029': 'Google Analyticsトラッキングコード',
    'PLKNOWLB030': 'ヘッダに表示するアイコン',
    'PLKNOWLB031': '送信ボタン',
    'PLKNOWLB032': 'スタイルシート',
    
    'COMMSG035': 'ファイルのサイズが大きすぎます。{{fileSize}}MB以下のファイルを選択してください。',
    'CBOTMSG001': 'お問い合わせありがとうございます。お困りごとの種類を教えてください。',
    'CBOTP001': 'お困りごとを選んでください',
    'CBOTMSG002': 'どの項目から調べますか？',
    'CBOTMSG002-AI-ON': '質問を入力してください。または、選択肢を選んで質問をすると、より正確に回答を得ることができます。',
    // 'CBOTMSG002-AI-ON': 'どの項目から調べますか？選択肢から選択、または質問を入力してください。',
    'CBOTMSG003': '{{option}}を選択してください。',
    'CBOTMSG003-AI-ON': '{{option}}を選択肢から選択、または質問を入力してください。',
    'CBOTMSG004': '{{totalKnowledge}}件のナレッジが見つかりました',
    'CBOTMSG004-AI-ON': '{{totalKnowledge}}件のナレッジが見つかりました。回答につながるものがあれば、クリックしてご覧ください。',
    'CBOTLB001': '問題は解決しましたか？',
    'CBOTLB007': 'ナレッジロボプレビュー画面',
    'CBOTLB010': '画面拡大',
    'CBOTLB011': '画面縮小',
    'CBOTLB012': 'チャット退出',
    'CBOTMSG006':'ご利用ありがとうございました。',
    'CBOTMSG007': '入力中のデータが保存されなく、有効中ナレッジロボ機能は無効となります。引き続きOFFにして宜しいでしょうか。',
    'CBOTMSG011': '必要事項を入力して、問い合わせを発行します。',
    'CBOTBT004': '始めからチャットを開始',
    'CBOTMSG013': '問い合わせの発行はキャンセルされました。',
    'CBOTMSG014': '対応可能担当者が見つかりました。少々お待ちください。',
    'CBOTLB009': '必要事項を入力して、問い合わせを発行します。',
    'USER11MSG001': 'お問い合わせありがとうございました。担当者より改めてご連絡させていただきます。',
    'USER11P001': 'メールアドレスを入力してください',
    'USER11LB004': '姓',
    'USER11LB005': '名',
    'USER11LB006': '姓(カナ)',
    'USER11LB007': '名(カナ)',
    'USER12MSG0015': '{{label_name}}を入力してください。',
    'USER11LB002': 'メールアドレス',
    'USER11LB011': '氏名',
    'USER11LB012': 'フリガナ',
    'CBOTLB014': '必要事項を入力して、ライブチャットを始めます。',
    'LABEL-MAX-NUMBER-CHARACTERS-DISPLAY-FOR-SELECT-OPTION': '選択肢の表示最大文字数',
    'LABEL-SHOW-FULL-TEXT': '全文を表示する',
    'LABEL-LIMIT-NUMBER-OF-CHARACTERS': '指定した文字数で省略する',
    
    // Chat bot
    'CBOTMSG012': "大変申し訳ございません。ただ今担当者が不在中ため、対応しかねます。",
    'CBOTMSG017': 'エージェントとのチャットから退出しますか？\nチャットを退出すると、お返事できない可能性がございます',
    'CBOTMSG018': 'ライブチャットが終わりました。',
    'CBOTMSG019': 'エージェントと接続しました。',
    'CBOTQUOTE01-1': 'AIの回答は正確性を保証するわけではありません。ご留意ください。　',
    'CBOTQUOTE01-2': 'Powered by GPT-4',

    //import csv knowledge 
    'KNOWCRLB001': 'ナレッジ一括投稿',
    'KNOWCRLB003': '処理行/全行数:',
    'KNOWCRBTN001': '前の行',
    'KNOWCRBTN002': '次の行',
    'KNOWCRBTN003': 'プレビュー',
    'KNOWCRBTN004': '停止',
    'KNOWCRBTN005': '再開',
    'KNOWCRBTN006': '一括保存',
    'KNOWCRBTN007': '一括投稿',
    'KNOWCRLB004': 'Webクローリング設定 ',
    'KNOWCRLB007': 'CSVファイル選択',
    'KNOWCRMSG002': 'ファイルの形式は正しくありません。',
    'KNOWCRMSG003': '500行以下のファイルをアップロードしてください。',
    'KNOWCRMSG004': '入力中のデータが保存されません。引き続き変更して宜しいでしょうか。',
    'KNOWCRMSG005': '一括投稿が完了しました。',
    'KNOWCRMSG006': '一括投稿が実行中です。引き続き終了して宜しいでしょうか。',
    'KNOWCRLB012': '取得方法',
    'KNOWCRLB036': 'ファイル指定',
    'KNOWCRLB037': 'ディレクトリ指定',
    'KNOWCRLB013': '取得ファイルタイプ',
    'KNOWCRLB014': 'プレビュー時にポップアップ画面で入力',
    'KNOWCRLB015': '事前に承認情報を設定',
    'KNOWCRP001': '階層数を入力してください',
    'KNOWCRP002': 'ファイルタイプを入力してください',
    'KNOWCRP003': 'ログインIDを入力してください',
    'KNOWCRLB006': '認証が必要なページの場合の認証方法',
    'KNOWCRLB005': 'ログインID',
    'KNOWCRLB008': '上書き更新用キー',
    'KNOWCRLB009': 'フィールド名マッピング',
    'KNOWCRLB010': 'フィールド名',
    'KNOWCRLB011': 'CSV内のカラム名',
    'COMMSG070': '入力形式が正しくありません。',
    'KNOWCRLB033': '進行中',
    'KNOWCRLB034': '指定しない ',
    'USER01BTN003':'キャンセル',
    'AUTH01MSG28':'利用可能な組織が見つかりません。',
    'AUTH01MSG25': '所属している組織は運用停止されました。より詳細情報には管理者にご連絡ください。',
    'USER11BTN002':'次へ',
    'KNOWCRLB035': 'ファイル名',
    'KNOWCRLB018': 'ステータス',
    'KNOWCRLB027': '添付ファイルアップロードエラー',
    'KNOWCRLB030': '成功',
    'KNOWCRLB031': '警告',
    'KNOWCRLB032': '失敗',
    'KNOWCRMSG013': '選択しているログファイルを削除してよろしいでしょうか。',
    'KNOWCRLB017': '日付',
    'KNOWCRLB019': 'メッセージ',
    'KNOWCRLB019_': "メッセージ", // this is new messagem, like above but using template for bulk action logs detail.
    'KNOWCRBTN008': 'ログ削除',
    'KNOWCRMSG009': '行目はアップロードが成功しました。',
    'KNOWCRMSG010': '行目はアップロードが成功しましたが、エラーが発生しました。',
    'KNOWCRMSG011': 'でアップロードが失敗しました。',
    'KNOWCRMSG012': '行目はアップロードしています。',
    'KNOWCRLB020': '必須項目が空',
    'KNOWCRLB021': 'データ型が違うエラー',
    'KNOWCRLB022': '認証エラー',
    'KNOWCRLB023': '文字数制限エラー',
    'KNOWCRLB024': '数値範囲エラー',
    'KNOWCRLB025': '通信エラー',
    'KNOWCRLB026': 'APIエラー',
    'PLKNOWBT002': 'デフォルトに戻す',
    'PLKNOWMSG007': 'ナレッジ公開サイトのスタイルシートをデフォルトに戻します。よろしいですか？',
    'LABEL-KN-ID-CSV-COLUMN': 'ナレッジID',
    'LABEL-SETTING-OVERWRITE-ATTACH-FILE': '添付ファイル',
    'LABEL-SETTING-UNIQUE-KEY-FIELD': 'キーフィールド',
    'LABEL-UNIQUE-KEY-CSV-COLUMN': 'CSV内のカラム名',
    'MSG-KNOWLEDGE-UNIQUE-KEY-ID': 'ナレッジIDが一致する既存ナレッジがある場合、上書きされます。',
    'MSG-KNOWLEDGE-UNIQUE-KEY-FIELD': '{{field_name}}（選択した、フォーマットの任意のフィールド）の入力値が一致する既存ナレッジがある場合、上書きされます。',
    'DROPDOWN-NO-OVERWRITE': '上書きしない',
    'LABEL-UPDATE-WHEN-OVERWRITE-ATTACH-FILE': '更新',
    'LABEL-ADD-WHEN-OVERWRITE-ATTACH-FILE': '追加',
    'LABEL-NO-CHANGE-WHEN-OVERWRITE-ATTACH-FILE': '変更無し',
    'LABEL-UNIQUE-KEY-FIELD-OF-FORM': 'フォーマットの各フィールド',

    'LABEL-DROPDOWN-DELETE-DATA': '削除用CSVカラム指定',
    'LABEL-DROPDOWN-KEY-FOR-DELETE': '削除用キー',
    'LABEL-DROPDOWN-CSV-COLUMN': 'CSV内のカラム名',
    'MSG-KNOW-LOG-DELETE-KNOWLEDGE-TICKET-SUCCEEDED': '{{processed_line}}/{{all_line}}行目は削除が成功しました。',
    'MSG-KNOW-LOG-DELETE-KNOWLEDGE-TICKET-FAILED': '{{processed_line}}/{{all_line}}行目のデータで削除が失敗しました。',


    // msg panel qa invited
    'USER03BTN011': '窓口開設',
    'USER04LB058': '推薦パートナープロジェクト',
    'USER04LB059': '代表者アドレス',
    'USER04LB060': '代表者姓' ,
    'USER04LB061': '代表者名',
    'USER04LB065': 'エスカレーションチケット発行',
    'MSG-CM-SEND-TICKET-SUCCESS': 'コンテンツを送信しました。',  //'チケットが送信されました。',
    'MSG-CM-SEND-EMAIL-TICKET-SUCCESS': 'メールが送信されました。',
    'USER04MSG033': '[corporationName]の[projectName]からサポート取引依頼があります。\nやり取り関係を開設しますか。',
    'USER03MSG007': "付与可能なジェネラルユーザーID数の上限を超えています。Accelaまで問い合わせください。",

    //Projetc management title
    'USER03LB002': 'プロジェクトタイプ',
    'COMLB002': '操作',
    
    //User management title
    'USER09LB001': '（メールアドレス）',
    'USER01LB003': '姓',
    'USER01LB004': '名',
    'USER01LB016': 'ユーザータイプ',
    'KNOWDEMSG002': 'リンクをコピーしました。',
    'USER14MSG028':'只今、ナレッジ機能が利用できません。管理者にご確認ください。',
    'KNOWDEMSG005':'本リンク先にアクセスできません。',
    'KNOWDEMSG006':'本リンク先のチケットは削除されました。',

    'KNOWCRMSG014': '※Google Driverでクローリングする場合、「[shareMail]」にファイルまたフォルダーにアクセス権限を付与することが必要です。',

    //Product management
    'TITLE-PRODUCT-LIST': '製品一覧',
    'BUTTON-ADD-PRODUCT': '製品を追加',
    'TABLE-COLUMN-PRODUCT-NAME': '製品名',
    'TABLE-COLUMN-SALES-STATUS': '販売状況',
    'CM-DROPDOWN-ON_SALE': '販売中',
    'CM-DROPDOWN-INACTIVE-SALE': '休止中',
    'MSG-CM-DATA-DUPLICATION': '入力された製品名がすでに使用されています。',
    'MSG-CM-SAVE-DATA-SUCCESS': '{{data_type}}が保存されました。',
    'MSG-CM-CONFIRM-EXIT-PAGE-WITHOUT-SAVING': '未保存の編集があります。保存しますか？',

    //Edition management
    'TITLE-EDIT-PRODUCT': '製品編集',
    'BUTTON-ADD-EDITION': 'エディションを追加',
    'LABEL-TABLE-ELECT-TRIAL-EDITION': 'お試し',
    'LABEL-TABLE-EDITION-NAME': 'エディション',
    'CM-LABEL-TABLE-SALES-STATUS':'販売状況',
    'CM-PLACEHODER-PRODUCT-NAME': '製品名',
    'CM-PLACEHODER-EDITION-NAME': 'エディション名',
    'LABEL-TABLE-VERSION-NAME': 'バージョン',
    'LABEL-TABLE-ORG-USAGE-STATUS': '契約(休止)',
    'CM-DROPDOWN-INTERNAL-SALE': '内部向け',
    'CM-BUTTON-DUPLICATE': '複製',
    'BUTTON-ADD-VERSION': 'バージョンを追加',
    'CM-PANEL-PRODUCT-MNG': '製品管理',
    'MSG-CM-DATA-DUPLICATION-EDITION': '入力されたエディション名がすでに使用されています。',

    //Version management
    'CREATE_NEW_VERSION': '新バージョン作成',
    'VERSION_NAME_PLACEHOLDER': 'バージョン名',
    'FUNCTION_SETTING': '機能',
    'BASIC_SETTING': '基本設定',
    'ADVANCED_SETTING': '拡張オプション設定',
    'MSG-CM-DELETED-TICKET': 'このチケットが削除されました。ページを更新してください。',
    'MSG-CM-VERSION-DATA-DUPLICATION': '入力されたバージョン名がすでに使用されています。',
    'MSG-CM-CONFIRM-DELETE-PERMANENTLY': '{{item}}を完全削除します。宜しいでしょうか。',
    'TITLE-EDIT-VERSION': 'バージョン編集',
    'MSG-AI-LICENSE-KEY-INVALID': 'OpenAIライセンスキーは無効です。有効なライセンスキーを確認の上、設定をお願いします。',

    //Register trial
    'CM-LABEL-PRODUCT-NAME': '製品名　　　',
    'LABEL-SUBDOMAIN': 'ご利用のサブドメイン',
    'LABEL-REGISTERED-GENERAL-USER-NUMBERS': '利用する一般ユーザー数',
    'PLACEHOLDER-REGISTERED-GENERAL-USER-NUMBERS': '一般ユーザー数を入力してください',
    'LABEL-UNIT-USER': '名',
    'MSG-ORG-TRIAL-EDITION-NOT-AVAILABLE': 'この製品のトライアル版が無効になりました。',
    'MSG-CM-SUBDOMAIN-DATA-DUPLICATION': 'ご記入のサブドメインは既に登録されています。',
    'MSG-CM-EMAIL-DATA-DUPLICATION': '入力された代表者メールアドレスがすでに使用されています。',
    'MSG-CM-OVER-MAXLENGTH': '{{maxLength}}文字以下で入力してください。',

    //CONFIRM REGISTER ORGANIZATION
    'CM-TITLE- FORM-CONFIRM': '組織の新規登録の確認',
    'LABEL-FORM-CONFIRM-GUIDELINE': '下記の登録内容が間違いないかご確認ください',
    'MSG-CM-EXPIRED-LINK': '本リンクが無効になりました。',
    'MSG-ORG-NOTICE-FOR-COMPLETING-REGISTRATION-CONFIRMATION-BEFORE': '組織の登録内容が既に確認されました。',
    'MSG-ORG-CANCEL-ORG-REGISTRATION-SUCCESS': '組織登録が既にキャンセルされました。',
    'LABEL-TAB-CONTRACT-INFO': '契約情報',
    'LABEL-TAB-BASIC-INFO': '組織情報',


    // Root org
    'CM-PANEL-ORG-DETAIL': '組織詳細',
    'TAB-ORG-INFO': '組織情報',
    'TAB-CONTRACT-INFO': '契約情報',
    'LABEL-ORG-NAME': '組織名',
    'LABEL-ADMIN-NAME': '代表者名',
    'LABEL-ADMIN-MAIL': 'メール',
    'LABEL-PHONE-NUMBER': '電話番号',
    'LABEL-STATUS': 'ステータス',
    'PLACEHOLDER-ORG-NAME': '組織名を入力してください',
    'LABEL-TAB-RESOURE-USAGE-STATUS': 'リソース利用状況',
    'LABEL-TAB-OPTION-USAGE-STATUS': 'オプション利用状況',
    'LABEL-TAB-PROVIDE-FUNTION': '提供機能',
    'LABEL-TAB-INVIDUAL-MAX-SETTING': '基本上限の組織個別設定',

    // resource usage 
    'LABEL-LASTEST-LOGIN-TIME': '最終ログイン日時',
    'LABEL-GENERAL-ID-NUMBER': '購入済一般ユーザーID数',
    'LABEL-INTERNAL-ID': '組織内ユーザーID',
    'LABEL-PARTNER-ID': 'パートナーID',
    'LABEL-UNASSIGNED-ID': '未割当',
    'LABEL-INTERNAL-CUSTOMER-ID-NUMBER': '組織内カスタマーID数',
    'LABEL-B2C-ID-NUMBER': '個人カスタマーID数',
    'LABEL-B2B-ID-NUMBER': '法人カスタマーID数',
    'LABEL-PROJECT-NUMBER': 'プロジェクト数',
    'LABEL-GENERAL-PROJECT-NUMBER': '一般プロジェクト数',
    'LABEL-INTERNAL-DESK-NUMBER': '組織内サービスデスク数',
    'LABEL-PUBLIC-DESK-NUMBER': '公開サービスデスク数',
    'LABEL-Q&A-COUNTER-NUMBER': '問い合わせ窓口設置数',
    'LABEL-SUPPORT-COUNTER-NUMBER': 'サポート窓口設置数',
    'LABEL-PUBLIC-KNOWLEDGE-SITE-NUMBER': '公開ナレッジ設置数',
    'LABEL-KNOWLEDGE-ROBO-NUMBER': 'ナレッジロボ設置数',
    'LABEL-Q&A-TICKET-NUMBER': 'チケット数',
    'LABEL-KNOWLEDGE-TICKET-NUMBER': 'ナレッジ数',
    'LABEL-FORMAT-NUMBER': 'フォーマット数',
    'LABEL-STORAGE-USAGE-NUMBER': 'ストレージ使用量',

    //option usage
    'LABEL-ADVANCED-NUMBER-OF-TICKET': 'チケット数追加',
    'LABEL-ADVANCED-NUMBER-OF-KNOWLEDGE': 'ナレッジ数追加',
    'LABEL-ADVANCED-STORAGE': 'ストレージ追加',
    'LABEL-ADVANCED-NUMBER-OF-QA-COUNTER': '問い合わせ窓口数追加',
    'LABEL-ADVANCED-NUMBER-OF-SUPPORTED-COUNTER': 'サポート窓口数追加',
    'LABEL-UNIT-SET': 'セット',

    //funtion settings
    'LABEL-FUNCTION-INTERNAL-Q&A-COUNTER-SETTINGS': '問い合わせ窓口設置',
    'LABEL-FUNCTION-PUBLIC-SERVICE-DESK-SETTINGS': '公開サービスデスクプロジェクト設置',
    'LABEL-FUNCTION-PUBLIC-WEB-FORM': '公開Webフォーム',
    'LABEL-FUNCTION-EMAIL-RESPONSE-FUNCTION': 'メール対応',
    'LABEL-FUNCTION-SOLUTIONDESK-KNOWLEDGE': 'SolutionDeskでのナレッジ利用',
    'LABEL-FUNCTION-PUBLIC-KNOWLEDGE-SITE': '公開ナレッジ',
    'LABEL-FUNCTION-KNOWLEDGE-BOT': 'ナレッジロボ',
    'LABEL-SYNONYM-SEARCH': '類義語検索',
    'LABEL-BATCH-COMMAND': '一括コマンド ',
    'LABEL-CONVERT-PDF': 'PDF検索',
    'LABEL-CHAT-AI': 'AI',
    'LABEL-LINCENSE-KEY-PERMISSION': '貸出ライセンスキー利用を許可する',
    'LABEL_FUNCTION_AI_AUTO': 'AI オート',
    'LABEL-FUNCTION-TASK-MNG': '課題管理機能',
    'LABEL-FUNCTION-ADVANCED-SETTINGS': '拡張オプション',
    'LABEL-FUNCTION-LOG-ANALYSIS': '分析',
    'LABEL-FUNCTION-IP-RESTRICTION': 'IPアドレス制限',

    //invidual basic settings
    'LABEL-BASIC-NUMBER-OF-TICKET': 'チケット数',
    'LABEL-BASIC-NUMBER-OF-KNOWLEDGE': 'ナレッジ数',
    'LABEL-BASIC-NUMBER-OF-FORM': 'フォーマット数',
    'LABEL-BASIC-NUMBER-OF-QA-COUNTER': '問い合わせ窓口数',
    'LABEL-BASIC-NUMBER-OF-SUPPORTED-COUNTER': 'サポート窓口数',
    'LABEL-BASIC-NUMBER-OF-GENERAL-PRJ': 'プロジェクト数',
    'LABEL-BASIC-NUMBER-OF-INTERNAL-CUSTOMER': '組織内カスタマーID数',
    'LABEL-BASIC-NUMBER-OF-B2C': '個人カスタマーID数',
    'LABEL-BASIC-STORAGE': 'ストレージ容量',
    'LABEL-BASIC-GENERAL-USER-FEE': '一般ユーザーID料金',
    'LABEL-BASIC-CHARGE': '基本料金',

    'LABEL-UNIT-TICKET-PER-PERSON': '個/人',
    'LABEL-UNIT-PRJ-PER-ORG': '個/組織',
    'LABEL-UNIT-PERSION-PER-ORG': '人/組織',
    'LABEL-UNIT-GB-PER-PERSON': 'Gb/人',
    'LABEL-UNIT-YEN': '円',
    'CM-LABEL-USER-UNIT': '人',

    // NORMAL CONTRACT DETAIL
    'CM-BUTTON-OK': 'OK',
    'CM-BUTTON-CANCEL': 'キャンセル',
    'CM-BUTTON-SAVE-AS-DRAFT': '下書き保存',
    'CM-BUTTON-EXPORT-QUOTATION': '見積発行',
    'CM-BUTTON-PAYEMENT-MANAGEMENT': '支払の管理',
    'CM-BUTTON-CONTRACT-HISTORY' : '購入履歴',
    'CM-BUTTON-UPDATE-CONTRACT' : '契約を変更',
    'CM-BUTTON-UPDATE': '変更',
    'LABEL-TITLE-UPDATE-CONTRACT': '契約変更',
    'CM-BUTTON-CONFIRM': '確定',
    'CM-LABEL-PLAN': 'プラン',
    'DROPDOWN-SELECT-PRODUCT': '製品',
    'DROPDOWN-SELECT-EDITION': 'エディション',
    'DROPDOWN-PAYMENT-CYCLE': '請求サイクル',
    'HYPERLINK-VIEW-PLAN': 'プラン一覧',
    'LABEL-PAYMENT-AMOUNT': '請求額',
    'LABEL-TOTAL-VALUE': '合計',
    'CM-LABEL-BASIC': '基本',
    'CM-LABEL-ADDITONAL-OPTION': 'オプション',
    'CM-LABEL-COLLUMN-BASIC': '基本',
    'CM-LABEL-COLLUMN-QUANTITY': '数量',
    'CM-LABEL-COLLUMN-UNIT-PRICE': '単価',
    'CM-TEXTBOX-GENERAL-USER-NUMBER': '一般ユーザーID数',
    
    'CM-TEXTBOX-ADDITIONAL-TICKET-SET': 'チケット上限追加',
    'CM-TEXTBOX-ADDITIONAL-KNOWLEDGE-SET': 'ナレッジ上限追加',
    'CM-TEXTBOX-ADDITIONAL-STORAGE-SET': 'ストレージ上限追加',
    'CM-TEXTBOX-ADDITIONAL-Q&A-COUNTER-SET': '問い合わせ窓口数上限追加',
    'CM-TEXTBOX-ADDITIONAL-SUPPORT-COUNTER-SET': 'サポート窓口数上限追加',

    'CM-LABEL-CURRENT': '現在',
    'LABEL-CURRENT-CONTRACT' : '現在契約中のプラン',
    'LABEL-TOTAL-PAYMENT-AMOUNT' : '支払済額',
    'LABEL-DISCOUNT': '値引き',
    'LABEL-EXPIRATION-DATE': 'お支払期限',
    'LABEL-USER': 'ユーザー',
    'LABEL-USAGE-STATUS': '利用状況',
    'LABEL-USED-FUNCTIONS': '利用機能',
    'LABEL-TABLE-USAGE-DETAIL': 'ご利用内容',
    'LABEL-TABLE-USAGE-STATUS': '利用中 / 上限',
    'LABEL-NUMBER-OF-GENERAL-USER': '一般ユーザーID数',
    'LABEL-NUMBER-OF-USED-TICKET': 'チケット数',
    'LABEL-NUMBER-OF-USED-KNOWLEDGE': 'ナレッジ数',
    'LABEL-NUMBER-OF-USED-FORM': 'フォーマット利用数',
    'LABEL-NUMBER-OF-Q&A-COUNTER': '問い合わせ窓口設置数',
    'LABEL-NUMBER-OF-SUPPORTED-COUNTER': 'サポート窓口設置数',
    'LABEL-NUMBER-OF-GENERAL-PRJ': 'プロジェクト数',
    'LABEL-NUMBER-OF-INTERNAL-CUSTOMER': '組織内カスタマーID数',
    'LABEL-NUMBER-OF-B2C': '個人カスタマーID数',
    'LABEL-USED-STORAGE': 'ストレージ使用量',
    'LABEL-ID': 'ID',
    'LABEL-NUMBER': '個',
    'LABEL-GB': 'GB',
    'CM-LABEL-PRODUCT-SELECTION': '製品',
    'CM-LABEL-EDITION-SELECTION': 'エディション',
    'CM-LABEL-VERSION-SELECTION': 'バージョン',
    'LABEL-INCREASE-RATE': '月契約の割増',
    // contract confirm
    'LABEL-NOTI-MSG': '新契約が「確定」ボタンをクリックしたら、即ちに利用可能です。お試し終了日から課金になります',
    'LABEL-TITLE-CONFIRM-CONTRACT': '契約変更内容確認',
    // Contract policy
    'LABEL-I-AGREE': '同意する',
    'LABEL-TITLE-CONTRACT-POLICY': '契約ポリシー',

    // payment contract
    'LABEL-TITLE-PAYMENT-METHOD':'支払方法',
    'LABEL-BANK-TRANSFER':'お振り込み',
    'LABEL-CREDIT-CARD':'クレジットカード',

    'GENERAL_USER': '一般ユーザー',
    'INTERNAL_USER': '組織内カスタマー',
    'LABEL-ESCALATE-SHARE-RESPONSE-TICKET': '元チケットの共有',
    'LABEL-ESCALATE-SHARE-RESPONSE-CHAT': 'カスタマーとのチャットを共有する',
    'LABEL-ESCALATE-SHARE-TICKET': 'チケット詳細を共有する',
    'LABEL-ESCALATE-SHARE-MAIL-TICKET': 'メールを共有する',
    'MSG-CONTRACT-UPDATE-WRONG': '利用したリソースより高い値を設定ください。',

    // register org new
    'LABEL-ADMIN-INFORMATION': '管理者の情報',
    'LABEL-ADMIN-FULL-NAME': '氏名',
    'PLACEHOLDER-ADMIN-LASTNAME': '氏',
    'PLACEHOLDER-ADMIN-FIRSTNAME': '名',
    'LABEL-COMPANY-NAME': '会社名',
    'PLACEHOLDER-COMPANY-NAME': '会社名',
    'LABEL-DEPARTMENT-NAME': '部・課',
    'PLACEHOLDER-DEPARTMENT-NAME': '部・課',

    'LABEL-CAREER-TITLE': '役職',
    'LABEL-MANAGER-OFFICER': '経営者・役員',
    'LABEL-GENERAL- MANAGER': '本部長・事業部長',
    'LABEL-MANAGER': '部長',
    'LABEL-SECTION-CHIEF-MANAGER': '課長・マネージャー',
    'LABEL-GENERAL-CHIEF-CHIEF': '係長・主任',
    'LABEL-GENERAL-EMPLOYEE': '一般社員',

    'LABEL-INDUSTRY': '業種',
    'LABEL-INFORMATION-SERVICE': '情報サービス',
    'LABEL-COMMUNICATION-MEDIA': '通信・メディア',
    'LABEL-CHEMICAL-PETROLEUM-COAL-PRODUCTS': '化学・石油・石炭製品',
    'LABEL-FOOD-PRODUCTS': '食品',
    'LABEL-ELECTRICITY-MECHANICAL': '電気・機械',
    'LABEL-STEEL-METAL': '鉄鋼・金属',
    'LABEL-TRANSPORTATION-EQUIMENT': '輸送用機器',
    'LABEL-PHARMACEUTICAL': '製薬',
    'LABEL-OTHER-MANUFACTURING': 'その他製造',
    'LABEL-SERVICE': 'サービス',
    'LABEL-CONSULTING': 'コンサルティング',
    'LABEL-CONSTRUCTION': '建設',
    'LABEL-FINANCE': '金融',
    'LABEL-INSURANCE': '保険',
    'LABEL-RETAIL-WHOLESALE': '小売・卸売',
    'LABEL-REAL-ESTATE': '不動産',
    'LABEL-WAREHOUSING-TRANSPORTATION-RELATED': '倉庫・運輸関連',
    'LABEL-ELECTRICITY-GAS': '電力・ガス',
    'LABEL-MEDICAL-INSTITUTION': '医療機関',
    'LABEL-GOVERMENT-OFFICE': '官公庁',
    'LABEL-LOCAL-GOVERNMENT': '自治体',
    'LABEL-EDUCATION': '文教',
    'LABEL-OTHERS': 'その他',

    'LABEL-ACCOUNT-INFORMATION': 'アカウント情報',
    'LABEL-ACCOUNT-NAME': 'アカウント名',
    'PLACEHOLDER-ACCOUNT-NAME': '会社名などご希望のアカウント名を入力してください。',
    'LABEL-ADMIN-ADDRESS': '管理者メールアドレス',
    'PLACEHOLDER-ADMIN-ADDRESS': '勤務先メールアドレス',
    'LABEL-ADMIN-PASSWORD': '管理者パスワード',
    'PLACEHOLDER-ADMIN-PASSWORD': '●●●●●●●●●●●●●●●',
    'LABEL-INPUT-PASSWORD-GUIDELINE': 'パスワードは8文字以上の半角英数字で設定してください。',
    'LABEL-SUBDOMAIN-2': 'ご希望のアカウントURL',
    // 'LABEL-REGISTERED-GENERAL-USER-NUMBERS': '',
    'PLACEHOLDER-REGISTERED-GENERAL-USER-NUMBERS-2'   : 'ご希望の一般ユーザー数を入力してください。', 	
    'TEXT-GENERAL-USER-GUIDELINE': '後で変更可能です。',
    // 'LABEL-UNIT-USER': '名',

    // confirm create org
    'LABEL-ADMIN-LASTNAME': '管理者姓',
    'LABEL-ADMIN-FIRSTNAME': '管理者名',
    'LABEL-SUBDOMAIN-3': 'ご利用のアカウントURL',

    'MSG-CM-DELETED-ORG': 'この組織が削除されました。ページを更新してください。',
    'MSG-NOTI-OF-DELETED-ORG': 'この組織が削除されました。',

    // view list org
    'CM-CONTRACT-STATUS-TRIAL': 'お試し',
    'CM-CONTRACT-STATUS-ACTIVE': 'アクティブ',
    'CM-CONTRACT-STATUS-CANCELLED': 'キャンセル',
    'CM-CONTRACT-STATUS-EXPIRED': '期限切れ',
    'LABEL-NOTI-CONTRACT-STATUS': '契約ステータス',
    'MSG-DRAG-DROP-FILE': 'ファイルをブラウズまたはドラッグ＆ドロップ',

    // View org infor
    'ORG-INFOR-LABEL-TITLE-ORG-INFOR': '組織情報',
    'ORG-INFOR-LABEL-ORG-LOGO': '組織ロゴ（推奨サイズ比率（横:縦）= 3:1）',		
    'ORG-INFOR-LABEL-SELECT-FILE': '画像ファイルを選択',
    'ORG-INFOR-LABEL-ORG-NAME': '組織名',
    'ORG-INFOR-LABEL-ADMIN-NAME': '代表者名',
    'ORG-INFOR-LABEL-ADMIN-MAIL': 'メール',
    'ORG-INFOR-LABEL-SUBDOMAIN': 'ご利用のサブドメイン',
    'ORG-INFOR-LABEL-AI-SETTING': 'AI設定',
    
    'ORG-INFOR-LABEL-USE-AI': 'AIを使用する',
    'ORG-INFOR-LABEL-LICENSE-AI': 'OpenAIライセンスキー',
    'ORG-INFOR-LABEL-CHATGPT-MODEL': 'ChatGPTモデル',
    'ORG-INFOR-LABEL-KNN-VALUE': '近傍数(k値)',
    'ORG-INFOR-LABEL-MIN-SCORE': '最小スコア (min_score)',
    'ORG-INFOR-LABEL-RESET': 'デフォルトに戻す',
    'ORG-INFOR-LABEL-AI-AUTO': 'AI オート',

    'LABEL-MSG-NOTI-LICENSE-AI': '現在、貸出ライセンスキーの利用が許可されていません。AI機能を利用するには、OpenAIのライセンスキーを取得し、ライセンスキー入力欄に入力してください。',
    'MSG-VERSION-HAVE-BEEN-DELETED': 'バージョンが削除されました。他のバージョンを設定してください。',
    'MSG-CM-BLOCKED-ORG': 'この組織は運用停止されました。ページを更新してください。',

    'CM-TITLE-AI-MNG': 'AI管理',
    'LABEL-AI-USAGE-RANGE': 'AI呼出提供範囲',
    'LABEL-PRJ-IN-USE': 'プロジェクト内',
    'LABEL-PRJ-IN-ORG': '組織内他のプロジェクト',
    'LABEL-PRJ-OUT-ORG': '組織外プロジェクト',
    'TITLE_AI_LOG': 'AI実行ログ参照リンク付与',
    'LABEL_AI_LOG': 'AIチャット内に「詳細」リンク表示',
    'TITLE_AI_RESPONSE_MESSAGE_RANDOMNESS': 'AIの応答メッセージランダム性',
    'LABEL_AI_RESPONSE_MESSAGE_RANDOMNESS': ' 応答メッセージランダム性 (temperature)',
    'MSG-AI-INVALID-TEMPERATURE-VALUE': 'ランダム性は0.0〜1.0の範囲で、整数部分は１文字で少数部分は1文字を入力してください。',

    'LABEL-USE-PROMPT': 'プロンプトを使う',
    'CM-LABEL-TITLE-PROMPT-LIST': 'プロンプト選択',
    "LABEL-PROMPT-USE-FOR-OUTSIDE-PRJ": "プロジェクト外",
    'LABEL-TOOLTIP-BUTTON-RESET': 'AIはナレッジを使わずに回答します。',

    'OPTION-SEARCH-SIMILAR': '話し言葉検索',
    'MSG-AI-LOANED-LICENSE-KEY-NOT-PERMITTED': '現在、貸出ライセンスキーの利用が許可されていません。AI機能を利用するには、OpenAIのライセンスキーを取得し、ライセンスキー入力欄に入力して下さい。',
    
    'LABEL-AI-LICENSE-KEY-SETTING-INSTRUCTION': 'この欄に独自に取得したOpenAIライセンスキーを入力してAIを使用することができます。空欄の場合、貸出キーが使用されます。',
    'MSG_AI_LICENSE_KEY_REQUIRED': '現在、貸出ライセンスキーの利用が許可されていません。AI機能を利用するには、OpenAIのライセンスキーを取得し、ライセンスキー入力欄に入力して下さい。',
    'MSG_AI_LICENSE_KEY_INVALID': 'OpenAIライセンスキーは無効です。有効なライセンスキーを確認の上、設定をお願いします。',
    
    //knowledge view option
    'CM-LABEL-PRJ-IN-USE': '現在プロジェクト',
    'CM-LABEL-ALL-KNOWLEDGE-IN-ORG': '組織内全ナレッジ',
    'CM-LABEL-INTERNAL-PRJ': '組織内プロジェクト',
    'CM-LABEL-EXTERNAL-PRJ': '組織外プロジェクト',

    'CM-DROPDOWN-PRJ-CREATE-KNOWLEDGE': '投稿先プロジェクト',
    'LABEL-BASIC-PROMPT': '基本プロンプト',
    'LABEL-BASIC-PROMPT-CHATBOT': 'プロンプト',



    'MSG-CM-INVALID-LINK': 'このリンクは無効です。',
    'MSG-AI-CONFIRM-DELETE-BASIC-PROMPT': 'この基本プロンプトを削除してもよろしいですか。',
    'MSG-PROCESSING-TIME-EXCEEDED': 'エラーが発生しました。しばらく経ってから再度お試しください。',
    'MSG-CM-PLEASE-ON-ADMIN-MODE': '管理者モードをONにしてから、更新内容を確認してください。',
    'MSG-CM-NO-DATA-FOUND': 'データが見つかりません。',
    'MSG-CM-CONFIRM-CLOSE-TAB': 'このタブ内で行ったチャットの履歴を削除します。宜しいでしょうか。',
    'MSG-AI-PASTED-LINK-ARE-NOT-PROMPT-KNOWN': 'プロンプトナレッジではありません。',
    'MSG-AI-PASTED-LINK-ARE-NOT-PROMPT-KNOWN-INTERATOR': 'プロンプトナレッジではありません。',
    'LABEL-TOOLTIP-BUTTON-SAVE-SEARCH-CONDITION': '現在のナレッジ一覧を本スレッドに設定します。AIはそのナレッジを元に回答します。',
    'MSG-AI-TOOLTIP-WHEN-OPEN-AI-ON-KNOLEDGE-LIST-PANEL': "この検索条件をもとにAIチャットを行います。",

    // knowlege field
    'CM-BUTTON-NOT-PUBLIC': '公開しない',
    'CM-BUTTON-PUBLIC-TO-OTHER-PRJ': '公開する',
    'CM-DROPDOWN-OPTION-OTHER-PRJ-ACTION': '他プロジェクト操作',
    // qa, helpdesk field
    'CM-DROPDOWN-OPTION-DO-NOT-SHOW': '表示しない',
    'CM-DROPDOWN-OPTION-CAN-VIEW': '閲覧のみ許可',
    'DROPDOWN-OPTION-CAN-VIEW-EDIT': '閲覧、編集を許可',
    'CM-DROPDOWN-OPTION-CUSTOMER-ACTION': 'カスタマー操作',

    'MSG-ERROR-WHEN-EDIT-TITLE-FIELD-TO-NOT-PUBLIC': '{{field_name}}のフィールドは{{field_label}}には「{{field_item_value}}」を設定していますが、タイトルに表示されます。引き続き設定してよろしいでしょうか？',
    'LABEL-PAGE-KNOWLEDGE-UNIT': 'ページ単位',
    'MSG-ERROR-WHEN-KNOWLEDGE-BEING-USED-BE-HIDDEN-OR-DELETED': '保存されたビューは存在しないか非表示のため、表示できません。',
    'MSG-CM-REQUIRED-INPUT': 'このフィールドは入力必須です。',
    'LABEL-LIMIT-NUMBER-OF-TOKEN-FOR-AI-ANSWER': 'AI回答向けのトークン数制限設定',
    'LABEL-NO-LIMITATION': '制限しない',
    'LABEL-LABEL-SPECIFY-TOKEN-NUMBER': '上限トークン数を設定',

    // PDF TICKET KNOWLEDGE
    'CM-LABEL-ATTRIBUTE-SETTINGS': '属性設定',
    'CM-LABEL-SYSTEM-ITEMS': '■ システム項目',
    'HYPERLINK-EXTRACT-TEXT': '抽出テキスト',
    'HYPERLINK-VECTOR-DATA': 'ベクトルデータ',
    'CM-LABEL-TOKEN-NUMBER': 'トークン数',

    // PDF KNOWLEDGE FIELD
    'LABEL-FIELD-OUTLINE': '概要',
    'LABEL-FIELD-PAGE-NUMBER': 'ページ番号',
    'LABEL-FIELD-CHAPTER': '章',
    'LABEL-FIELD-SECTION': '節',
    'LABEL-FIELD-SUB-SECTION': '項',
    'LABEL-FIELD-ITEM': '目',
    'LABEL-FIELD-BOOKMARK': 'しおり',
    'LABEL-FIELD-ATTACHMENT': '添付ファイル',
    'LABEL-FIELD-PARENT-CONTENT': '親コンテンツ',
    'LABEL-CHILD-CONTENT': '子コンテンツ',
    'LABEL-CONFIG-PUBLIC-WEB-FORM': '公開Webフォームの設定',
    'LABEL-GO-TO-CONFIG-PUBLIC-WEB-FORM': '公開Webフォーム設定画面へ',

    // Search synonym
    'LABEL-SYNONYM-KNOW-SETTINGS': '■ 類義語ナレッジ設定',
    'LABEL-USE-AS-SYNONYM-KNOW': '類義語フォーマットとして使用する',
    'MSG-FORM-USE-AS-SYNONYM-FORM-IF-INCLUDE-LIST-OF-SYNONYMS-FIELD': 'このフォーマットを類義語フォーマットとして使用します。',
    'MSG-CM-USE-SYNONYM-KNOW-TO-SEARCH': '類義語ナレッジが使用されます。',
    'MSG-CM-USE-CURRENT-SYNONYM-KNOW-TO-SEARCH': '現在プロジェクトの類義語が適用されます。',
    '類義語展開する': '類義語展開する',
    'MSG-CM-OVER-MAX-CAPACITY': '{{max_capacity}}以下で入力してください。',

    // knowledge robo setting
    'LABEL-AI-FUNCTION': 'AI機能',

    'MSG-AI-ALLOW-ASK-IN-SPOKEN-LANGUAGE': '話し言葉で質問できます',
    'MSG-NORMAL-ASK-IN-SPOKEN-LANGUAGE': 'あなたのメッセージ',
    "MSG-AI-RESPOND-TO-USER-MSG-ON-PREVIEW-KNOW-ROBO": "こちらはAIからの回答です。",
    "MSG-SEARCH-INCLUDES-PDF-OF-KNOWLEDGE" : "親ナレッジを検索する際に添付PDFのテキストも含めて検索します。",
    "LABEL-SPLIT-PDF-INTO-PAGES": "添付ファイルを検索対象にする",
    "MSG-CM-BACK-TO-DEFAULT": "のデフォルトに戻します。よろしいですか？",
    "LABEL-BUTTON-DOWLOAD-LINUX-COMMAND": 'Linux用コマンドダウンロード',
    "LABEL-BUTTON-DOWNLOAD-WINDOWS-COMMAND": "Windows用コマンドダウンロード",
    "MSG-KNOW-LOG-AUTO-CREATE-TAG-ITEM-SUCCEEDED": "の選択項目を追加しました。",
    "MSG-AI-LICENSE-KEY-EXPIRED": "OpenAIライセンスキーは無効となりました。有効なライセンスキーを再度設定してください。",
    "WARNING_EXCEED_MAX_TOKEN": "入力トークンサイズを超えたため途中まで反映",
    "MSG-AI-LICENSE-KEY-HAVE-BEEN-INVALID-CONTACT-ADMIN": 'APIのライセンスキーが無効です。管理者に連絡してください。',
    "MSG_OPEN_AI_API_ERROR":'APIのリクエスト上限に達しました。管理者に連絡してください。',
    "MSG_OPEN_AI_API_ERROR_PATH_TERM":"APIエラーが発生しました。管理者に連絡してください。（エラーコード：",

    // Bulk-action
    'EDIT_TAG_FIELD': 'タグ編集',
    'EDIT_PUBLIC_RANGE': '公開範囲編集',
    'EXPORT_CSV': 'エクスポート',
    'BACK_TO_DRAFT': '下書きに戻す',
    'SENT_TO_PUBLIC': '公開',
    'DUPLICATE': '複製',
    'DELETE': '削除',
    'SOFT_DELETE': '削除',
    'HARD_DELETE': '完全削除',
    'RESTORE': '復元',
    'KNOWLEDGE_BOOKMARK_TICKET': 'タグ編集',

    //options
    'EXPORT_FORMAT_LABEL': "エクスポート形式",
    'EXPORT_CHAT_THREAD_LABEL': "チャットスレッド",
    'EXPORT_CHAT_THREAD_INCLUDE': "含める",
    'EXPORT_CHAT_THREAD_NOT_INCLUDE': "含めない",

    'HELPDESK_EXPORT_CSV': 'エクスポート',
    'HELPDESK_BACK_TO_DRAFT': '取り戻し',
    'HELPDESK_SENT_TO_PUBLIC': '送信',
    'HELPDESK_DUPLICATE': '複製',
    'HELPDESK_DELETE': '削除',
    'HELPDESK_SOFT_DELETE': '削除',
    'HELPDESK_HARD_DELETE': '完全削除',
    'HELPDESK_RESTORE': '復元',
    'HELPDESK_ARCHIVE': 'アーカイブ',
    'HELPDESK_BACK_TO_NEW_SUPPORT': '新着対応に戻す',

    'MSG-CM-CONFIRM-DELETE-CONTENT': 'このコンテンツを削除してよろしいですか？',
    'MSG-CM-CONFIRM-DELETE-CONTENT-ON-TICKET-LIST': 'このコンテンツを削除すると以降のカスタマー対応ができなくなります。よろしいですか？',
    'MSG-CM-DELETE-TICKET': 'チケットを削除すると、チケットの編集やチャットができなくなります。よろしいですか？',
    'MSG-CM-DELETE-PERMANENTLY-TICKET': 'このチケットを完全削除します。宜しいでしょうか。',

    'MSG-CM-CONFIRM-RESTORE-TICKET': 'このチケットを復元して宜しいでしょうか？',
    'MSG-CM-CONFIRM-RESTORE-CONTENT': 'このコンテンツを復元してよろしいですか？',

    'MSG-CM-ARCHIVE-TICKET': 'このチケットをアーカイブして宜しいでしょうか？',
    'MSG-CM-BACK-TO-NEW-TICKET': 'このチケットをアクティブにしてよろしいですか？',
    'MSG-CM-CONFIRM-BACK-TO-NEW-SUPPORT': 'このコンテンツをアクティブにしてよろしいですか？',

    'MSG-CM-CONFIRM-DELETE-MULTI-CONTENT': '選択中の[number]件のコンテンツをすべて削除してよろしいですか？',
    'MSG-CM-CONFIRM-DELETE-PERMANENTLY-MULTI-CONTENT': '選択中の[number]件のコンテンツをすべて完全削除します。よろしいですか？',
    'MSG-CM-CONFIRM-RESTORE-MULTI-CONTENT':	'選択中の[number]件のコンテンツをすべて復元してよろしいですか？',
    'MSG-KNOW-CONFIRM-BACK-TO-DRAFT-MULTI-CONTENTS': '選択中の[number]件のコンテンツをすべて公開状態から下書き状態へ戻してよろしいですか？',
    'MSG-CM-CONFIRM-SEND-TO-PUBLIC-CONTENT': '選択中の[number]件のコンテンツをすべて公開してよろしいですか？',
    'MSG-KNOW-CONFIRM-DUPLICATE-MULTI-CONTENT': '選択中の[number]件のコンテンツをすべて複製してよろしいですか？',
    'MSG-CM-CONFIRM-ARCHIVE-MULTI-CONTENT': '選択中の[number]件のコンテンツをすべてアーカイブしてよろしいですか？',
    'MSG-CM-CONFIRM-BACK-TO-NEW-MULTI-CONTENT': '選択中の[number]件のコンテンツをすべてアクティブにしてよろしいですか？',
    'MSG-KNOW-FORM-NOT-SHARE': 'ナレッジ複製に利用できるフォーマットがありません。複製に失敗しました。',

    'MSG-KNOW-TOOLTIP-BULK-ACTION-1': '検索結果のすべてのコンテンツに対して一括操作を行います。',
    'MSG-KNOW-TOOLTIP-BULK-ACTION': 'この操作を実行するには、編集権限が必要です。',
    'MSG-KNOW-BULK-ACTION-LOADING': '一括操作を実行中です。しばらくお待ちください。',

    'MSG-KNOW-CONFIRM-DELETE-MULTI-KNOWLEDGES':	'チケットを削除すると、チケットの編集やチャットができなくなります。よろしいですか？',
    'MSG-KNOW-CONFIRM-DELETE-PERMANENTLY-MULTI-KNOWLEDGES':	'このチケットを完全削除します。宜しいでしょうか。',
    'MSG-KNOW-CONFIRM-RESTORE-MULTI-KNOWLEDGES': 'このチケットを復元して宜しいでしょうか？',
    'MSG-CM-CONFIRM-EXPORT-CSV': 'エクスポートを開始します。エクスポート完了まで数分かかる場合があります。よろしいですか？',

    'MSG-KNOW-TOOLTIP-WHEN-ENABLE-ICON-EXPORT-CSV': 'コンテンツ一覧をCSV形式でエクスポートします。',
    'MSG-CS-TOOLTIP-WHEN-ENABLE-ICON-EXPORT-CSV': 'コンテンツ一覧をCSV形式でエクスポートします。',
    'MSG-KNOW-TOOLTIP-WHEN-DISABLE-ICON-EXPORT-CSV': 'コンテンツ一覧をCSV形式でエクスポートします。エクスポートするには、フォーマットで絞り込んでください。',
    'MSG-CS-TOOLTIP-WHEN-DISABLE-ICON-EXPORT': 'コンテンツ一覧をCSV形式でエクスポートします。エクスポートするには、フォーマットで絞り込んでください。',

    'MSG-CM-TOOLTIP-WHEN-HOVER-ICON-LOADING': 'CSVファイルをエクスポート中です。',

    'MSG-CM-DISCONNECT-NETWORK-WHEN-EXPORT-CSV': 'ネットワーク接続が切れました。再度エクスポートしてください。',
    'MSG-CM-API-ERROR-WHEN-EXPORT-CSV': '通信エラーが発生しました。再度エクスポートしてください。',
    'MSG-CM-OVER-SIZE-FILE-ERROR-WHEN-EXPORT-CSV': '一括でエクスポートできるコンテンツは100,000件までです。',
    'MSG-CM-OTHERS-ERROR-WHEN-EXPORT-CSV': 'エラーが発生しました。再度エクスポートしてください。',
    'MSG-KNOW-CONFIRM-BACK-TO-DRAFT-MULTI-KNOWLEDGES': 'このチケットを公開状態から取り戻して宜しいでしょうか？',
    'MSG-KNOW-CONFIRM-SEND-TO-PUBLIC-MULTI-KNOWLEDGES': 'このチケットを公開して宜しいでしょうか？',
    'MSG-KNOW-TOOLTIP-DISABLE-BACK-TO-DRAFT': 'この操作を実行できのは投稿者のみとなります。',
    
    // Edit multi public range
    'DROPDOWN_EDIT_PUBLIC_RANGE': '変更する権限',
    'BASIC_VIEW': '基本閲覧権限',
    'BASIC_EDIT': '基本編集権限',
    'ADDITIONAL_VIEW': '追加閲覧権限',
    'ADDITIONAL_EDIT': '追加編集権限',
    'LABEL_PRJ_IN_ORG': '組織内の特定プロジェクト',
    'LABEL_B2B_CUSTOMER_PRJ': 'B2Bカスタマープロジェクト',
    'LABEL_B2B_HELPDESK_PRJ': 'B2Bサポートプロジェクト',
    'LABEL_ORTHERS_PRJ': 'その他',

    // BulkAction Result Popup
    'BULK_ACTION_RESULT_POPUP_LABEL': '更新完了',
    'VIEW_LOG_DETAIL': 'ログ詳細',
    'SUBMIT': 'OK',
    'SUCCESS_COUNT': '成功',
    'FAIL_COUNT': '失敗',
    'SELECTED_TICKET_COUNT': '選択したコンテンツ',
    'EIDT_TAG_ITEM_FIELD' :'タグ編集',
    'HELPDESK_EDIT_TAG_FIELD' : 'タグ編集',
    'SELECT_TAG_FIELD':'選択フィールド',
    'MSG-KNOW-TOOLTIP-WHEN-DISABLE-EDIT-TAG-FIELD': '選択フィールドを編集するには、フォーマットで絞り込んでください。',
    'MSG-KNOW-TOOLTIP-WHEN-DISABLE-HELPDESK-EDIT-TAG-FIELD': '選択フィールドを編集するには、フォーマットで絞り込んでください。',
    'MSG-KNOW-TOOLTIP-WHEN-DISABLE-ACTION-DUPLICATE': 'コンテンツを複製するには、フォーマットで絞り込んでください。',

    'MSG-CM-RESTORE-TICKET-SUCCESS': 'ナレッジを復元しました',
    'MSG-CM-EXCEED-MAX-CONTENT-BULK-ACTION': '一括で操作できるコンテンツは10,000件までです。',
    'CBOTMSG005': '※その他の選択肢は下の入力ボックスから選んでください。',
    'MSG-CM-CONTENT-NOT-EXIST': 'コンテンツが存在しません。',
    'MSG-KNOW-EDIT-PERMISSION-EXCEED-VIEW-PERMISSION': 'コンテンツの編集権限が閲覧権限を超えています。',
    'MSG-CM-TAG-FIELD-NOT-EXIST': '操作対象のタグフィールドが存在しません。',
    'MSG-CM-TAG-ITEM-NOT-EXIST': '操作対象のタグ項目が存在しません。',
    'MSG-CM-DO-NOT-HAVE-PERMISSION': 'この操作を実行する権限がありません。',
    'MSG-CM-PUBLIC-RANGE-CAN-NOT-SET': '設定できない公開範囲です。',
    'MSG-CM-REQUIRED-TAG-FIELD-EMPTY': '必須フィールドが入力されていないため、更新できません。',
    'MSG-CM-CONTENT-CAN-NOT-PUBLIC': ' 必須フィールドが入力されていないため、コンテンツは公開できません',
    'MSG-KNOW-CONFIRM-MOVE-FORM-DUPLICATE': '複製のため、「Form_name」フォーマットをプロジェクトで使用できるようにします。よろしいでしょうか？',
    'MSG-CM-BULK-ACTION-PROCESS-SUCCESSFULLY': '処理に成功しました。',
    
    'CM-BUTTON-SAVE-DRAFT': '一時保存',

    'チケット': 'チケット',
    'ナレッジ': 'ナレッジ',
    'チケット一覧': 'チケット一覧',
    'チケット詳細': 'チケット詳細',
    'チケットチャット': 'チケットチャット',
    // 'MSG-CM-BULK-ACTION-PROCESS-SUCCESSFULLY': "処理に成功しました。",
    'INQUIRY-USER-NAME': '問い合わせユーザー名',
    'INQUIRY-USER-EMAIL_ADDRESS': '問い合わせユーザーメールアドレス',

    //setting iframe
    'LABEL-MESSAGE-DISPLAY-SETTING':"問い合わせ完了ページに表示するメッセージ設定",
    'LABEL-EMAIL-SUBJECT-SETTING':"問い合わせ完了時に送信するメール件名",
    'LABEL-EMAIL-BODY-SETTING':"問い合わせ完了時に送信するメール本文",
    "OVER-2000-CHARS": "2000文字の以下で入力してください。",
    "OVER-50-CHARS": "50文字の以下で入力してください。",
    'MSG-CHANGE-PANEL-WIDTH': 'パネルを{{panelSize}}パネルの幅に変更します。',

    'MSG-CM-CONFIRM-DELETE-LOGIC-CONTENT-1': 'チケットを削除すると、チケットの編集やチャットができなくなります。よろしいですか？',
    'MSG-CM-CONFIRM-DELETE-LOGIC-CONTENT-2': 'チケットを削除すると、チケットの編集やチャットができなくなります。よろしいですか？',
    
    'MSG-CM-RESTORE-CONTENT': 'チケットが復元されました。',
    'MSG-CM-RESTORE-CONTENT-SUCCESSFULLY': 'コンテンツを復元しました。',
    
    'MSG-CM-CONFIRM-DELETE-PERMANENTLY-CONTENT': '完全削除すると、復元できません。引き続き完全削除して宜しいでしょうか？',
    'MSG-CM-CONFIRM-DELETE-CONTENT-COMPLETELY': '完全削除すると復元できません。このコンテンツを完全削除してよろしいですか？',

    //panel label
    "list_ticket": "",
    "chat_thread": "",
    "detail_ticket": "",
    "add_ticket": "",
    "detail_ticket_approve": "",

    "form_management": "",
    "list_ticket_knowledge": "",

    "helpdesk_ticket_list": "チケット一覧",
    "helpdesk_chatThread": "チケットチャット",
    "helpdesk_ticket_detail": "チケット詳細",
    "helpdesk_ticket_list_drilldown_and_detail_search": "チケットドリルダウン&詳細検索",

    "customer_ticket_list": "問い合わせ一覧",
    "customer_chatThread": "問い合わせチャット",
    "customer_ticket_detail": "問い合わせ詳細",

    "knowledge_ticket_list": "ナレッジ一覧",
    "knowledge_helpdesk_ticket_list": "",
    "knowledge_chatThread": "ナレッジチャット",
    "knowledge_ticket_detail": "ナレッジ詳細",
    "knowledge_helpdesk_ticket_detail": "",
    "knowledge_create_ticket": "",
    "knowledge_helpdesk_drilldown_and_detail_search": "",
    "knowledge_prj_inuse_drilldown_and_detail_search": "ナレッジドリルダウン＆詳細検索",
    "knowledge_upload_csv": "ナレッジ一括投稿",

    "system_management": "組織設定",
    "system_user_management": "",
    "system_user_profile": "",
    "system_project_management": "",
    "system_project_profile_management": "",
    "system_project_user_management": "",
    "system_project_customer_management": "",
    "system_corporation_customer_management": "",
    "system_project_setting_iframe": "",
    "system_public_knowledge_site_setting": "",
    "system_project_setting_chatbot": "",
    "system_project_setting_ai": "",
    "system_normal_contract_detail": "",

    "project_management": "プロジェクト設定",
    "organization_management": "",
    "format_management": "フォーマット設定",
    "personal_management": "個人設定",

    "system_org_profile": "",
    "system_field_management": "",
    "list_notification": "通知一覧",
    "root_list_org": "組織管理",
    "root_edit_org_contract": "組織詳細",
    "qa_category_setting": "",
    "preview_chatbot": "ナレッジロボプレビュー画面",
    "product_management": "製品管理",
    "version_management": "",
    "open_chat_ai": "AIチャット",
    'export_confirm': "実行",
    
    // Bookmark
    'BOOKMARK_TAG_SETTING': 'ブックマークタグ設定',
    'ADD_MORE': 'を追加',
    'CAN_ADD_MORE_THIS_PLACE': 'ここでアイテムを追加できます。',
    'SAVE_AND_CLOSE': '保存して閉じる',
    'PRIVATE_BOOKMARK_TAG': '個人用ブックマークタグ',
    'SHARE_BOOKMARK_TAG': '共有ブックマークタグ',
    'MSG_VALIDATE_BOOKMARK_NAME_EMPTY': 'ブックマークタグ名をご記入ください。',
    'MSG_VALIDATE_BOOKMARK_DUPLICATE': '入力されたブックマーク名が既に使用されています。',
    'BOOKMARK_SETTING_': 'ブックマークタグ設定を保存しますか？',
    'DO_YOU_WANT_TO_SAVE_BOOKMARK_SETTING': 'ブックマークタグ設定を保存しますか？',
    'DO_YOU_WANT_TO_CANCEL_BOOKMARK_SETTING': 'ブックマークタグ設定の編集を破棄しますか？',
    'MSG_BOOKMARK_SAME_NAME_SHARED_BOOKMARK': '共有ブックマークタグとタグ名が重複しています。タグ名を変更してください。',
    'PRIVATE_BOOKMARK_TOTAL': '個人用ブックマークタグ数',

    'SETTING_ENABLE_LIST_TICKET_ROBO': '絞り込み完了時にナレッジ一覧を表示する',
    'MESSAGE_SUGGEST_USING_AI': '質問を入力してください。',
	
    // IP Address restrict 
    "IP_ADDRESS_RESTRICT_FOR_PUBLIC_FUNCTION": "公開系機能のIPアドレス制限",
    "APPLY_IP_ADDRESS_RESTRICTION": "IPアドレス制限を適用する",
    "IP_ADDRESS_SETTING_DESCRIPTION": "公開系機能にアクセス可能とするIPアドレス、またはサブネットマスクを、1行に1つ入力してください。\n最大150行まで設定可能です。\nこの設定は、本プロジェクトで管理されている公開ナレッジサイト、ナレッジロボ、公開Webフォームに適用されます。",
    "IP_ADDRESS_RANGE_PLACEHOLDER": "例）\n123.456.789.123\n123.456.789.124/24",
    "IP_ADDRESS_FORMAT_WRONG": "入力形式が正しくありません",
    "IP_ADDRESS_MAX_EXCCED": "登録できるIPの上限は{{maxIpAddress}}個までです",
    "IP_ADDRESS_DUPLICATE": '同データが存在しています。',
    "PRIVATE_TAG": "プライベートタグ",
    "BOOKMARK_TAG": "ブックマークタグ",
    "IP_ADDRESS_ORG_SETTING_DESCRIPTION": 'SolutionDeskの画面にアクセス可能とするIPアドレス、またはサブネットマスクを、1行に1つ入力してください。最大150行まで設定可能です。',
    "プライベートタグ": "プライベートタグ",
    "個人用ブックマークタグ": "個人用ブックマークタグ",
    "共有ブックマークタグ" : " 共有ブックマークタグ",
    
    "MSG-KNOW-HIDE-VIEW-SETTING-CATEGORY": "このビューはナレッジロボで使用されています。ビューを非表示にしてよろしいですか？",
    'MSG-CM-EXPIRED-SESSION-1': 'セッションの有効期限が切れました。',
    'MSG-CM-EXPIRED-SESSION-2': 'OKをクリックするとログアウトし、編集中の内容は破棄されます。',


    // Setting Email Response
    "EMAIL_RESPONSE_SETTING": "メール送受信設定",
    "EMAIL_RESPONSE_SETTING_FUNCTION": "メール対応機能",
    "PROJECT_EMAIL_ADDRESS": "プロジェクトメールアドレス",
    "PROJECT_EMAIL_ADDRESS_NAME": "呼び名",
    "EMAIL_ADDRESS_TO_USE_AS_THE_SENDER": "メール差出人として使用するメールアドレス",
    "ALWAYS_INCLUDE_CC_EMAIL_ADDRESS": "常にCcに含めるメールアドレス",
    "ALWAYS_INCLUDE_BCC_EMAIL_ADDRESS": "常にBccに含めるメールアドレス",
    "SIGNATURE": "署名",
    "EMAIL_ADDRESS_TO_USE_AS_THE_SENDER_MESSAGE": "ⓘ SolutionDeskからメールチケットを発行する場合、ここに入力したメールアドレスが差出人として使用されます。空欄の場合はプロジェクトメールアドレス{{project_email_address}}が差出人として使用されます。",
    "メール": "メール",
    "MSG-EMAIL-TICKET-PROJECT-NAME-OVER": "2000文字以下で入力してください。",
    'MSG_EMAIL_TICKET_DUPLICATED_PROJECT_EMAIL': 'このプロジェクトメールアドレスはすでに使用されています。違うものに変更してください。',
    'MSG_SEND_VERIFY_EMAIL_CONFIRM': '「メール差出人として使用するメールアドレス」に指定したメールアドレス宛に認証メールを送付しました。メールに記載のURLをクリックして、認証を完了してください。',

    "EMAIL_SUBJECT": "件名",
    "EMAIL_BODY": "本文",

    // Email ChatThread
    "EMAIL_DETAIL": "メール詳細",
    "EMAIL_DELIVERY": "メール配信",
    "EMAIL_STATUS_SENT": "配信済",
    "EMAIL_STATUS_UNSEND": "未配信",
    
    "MGS-FILE-OVER-CAPACITY": ' ファイルを{{fileSize}}MB以下で添付してください。',
    
    // Email Ticket Info
    "CHAT_MESSAGE_CREATOR": "チャットメッセージの作成者",
    "CHAT_MESSAGE_UPDATOR": "チャットメッセージの編集者",
    "CHAT_MESSAGE_SENDER": "チャットメッセージの送信者",
    "CHAT_MESSAGE_CREATED_TIME": "チャットメッセージの作成日時",
    "CHAT_MESSAGE_UPDATED_TIME": "チャットメッセージの編集日時",
    "CHAT_MESSAGE_SENT_TIME": "チャットメッセージの送信日時",

    "MSG-CM-CHANGED-DATA": "データが変更されました。ページを更新してください。",
    'MSG-CM-CANCEL_REPLY': 'コンテンツの変更内容を破棄しますか？',

    "LABEL-USING-YOUR-ORG-LICENSE-KEY": "組織の独自ライセンスキーを利用中",
    "LABEL-SETTING-CHATGPT-MODEL": "『OpenAIライセンスキー』を入力している場合にのみ変更可能です。AIチャットで使用するChatGPTのモデルを指定します。デフォルトで選択されているモデルが推奨値です。推奨値以外のモデルは動作保証対象外となります。",
    "LABEL-SETTING-KNN": "デフォルトで入力されている値が推奨値です。推奨値以外は動作保証対象外となります。",
    "LABEL-SETTING-MIN-SCORE": "デフォルトで入力されている値が推奨値です。推奨値以外は動作保証対象外となります。",
    "MSG-CONFIRM-RESET-SETTING-AI": "ChatGPTモデル、k値、最小スコアの値をデフォルトに戻します。よろしいですか？",
    "MSG-AI-INVALID-K-VALUE": 'k値は1～10000の範囲で入力して下さい。',
    "MSG-AI-INVALID-MIN-SCORE-VALUE": 'min_scoreは0～1の範囲で入力して下さい。',
    'MSG-AI-LOG-CHANGED-DATA': '実行ログでデータが変更されました。ページを更新してください。',
    'MSG-AI-AUTO-LOG-PAUSE': 'AIオート処理は一時停止されています',
    'MSG-AI-AUTO-LOG-REPLAY': 'MSG-AI-AUTO-LOG-REPLAY',
    'MSG-AI-AUTO-LOG-RETRY': '時間を空けて再度呼び出してください…',
    'MSG-AI-AUTO-LOG-ERROR': 'エラーが発生しました。しばらく経ってから再度お試しください。',

    // AI Auto Log
    'EXECUTION_LOG': '実行ログ',
    'AI_AUTO_DELETE_LOG_SUBSCRIPTION': '1週間経過後にログを自動削除する',
    'EMPTY_EXECUTION_LOG': '実行ログはありません。',

    /**AI AUTO. start */
    "MSG-AI-INVALID-PROMPT-EXECUTION-DATETIME": 'プロンプト実行日時は「YYYY/MM/DD-HH:M0」の形式で入力してください。',
    'MSG-CM-TAG-ITEM-UNAVAILABLE':"現在利用できないタグが含まれています。",
    'MSG-CM-INVALID-LINK-PROMPT':'プロンプトナレッジではありません。',

    /**AU AUTO. end */

    /**Mermaid START */
    'MERMAID': 'ダイアグラム',
    /**Mermaid END */

    "MSG-RESTORE-EXISTED-USER": "ユーザーを再度追加できません。別の管理画面にすでに追加されています。"
};

export default jp;
